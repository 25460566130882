import { types } from '../types/types';

// {
//     titulo: 'Mi primer evento',
//     notas: 'Estas son las notas del evento',
//     fechaInicio: new Date(),
//     fechaFin: new Date(),
//     id: 9876553,
//     tipoEvento: 1,
//     user: {
//         _id: '123',
//         name: 'Patricia'
//     }
// }


const initialState = {
    eventos: [],
    eventoActivo: null,
    vistaCalendario: 'month',
};

export const calendarioReducer = ( state = initialState, action ) => {

    // console.log('calendarioReducer action: ', action);
    // console.log('calendarioReducer state: ', state);

    switch ( action.type) {
        case types.calVistaCalDia:
            return {
                ...state,
                vistaCalendario: 'day'
            };

        case types.calVistaCalMes:
            return {
                ...state,
                vistaCalendario: 'month'
            };

        case types.calVistaCalSemana:
            return {
                ...state,
                vistaCalendario: 'week'
            };

        case types.eventoSeleccionado:
            return {
                ...state,
                eventoActivo: action.payload
            };

        case types.eventoNuevo:
            return {
                ...state,
                eventos: [
                    ...state.eventos,
                    action.payload
                ]
            };

        case types.eventoLimpiarSeleccionado:
            return {
                ...state,
                eventoActivo: null
            };

        case types.eventoActualizado:
            return{
                ...state,
                eventos: state.eventos.map(
                    e => ( e.id === action.payload.id ) ? action.payload : e
/*                     e => {
                        console.log('calendarioReducer -> types.eventoActualizado e: ', e);
                        console.log('calendarioReducer -> types.eventoActualizado action.payload: ', action.payload);
                        if ( e.id === action.payload.id ){
                            console.log('calendarioReducer -> types.eventoActualizado. DEVOLVEMOS action.payload: ', action.payload);
                            return action.payload;
                        } else {
                            console.log('calendarioReducer -> types.eventoActualizado. DEVOLVEMOS e: ', e);
                            return e;
                        }
                    } */
                )
            };

        case types.eventoBorrado:
            return{
                ...state,
                eventos: state.eventos.filter(
                    e => ( e.id !== state.eventoActivo.id ) // Filtro los eventos quitando el que está seleccionado
                ),
                eventoActivo: null
            };

        case types.eventoListado:
            return{
                ...state,
                eventos: [ ...action.payload ]
            };

// TIPOS DE EVENTO
        case types.tipoEventoListado:
            return {
                ...state,
                tiposEvento: [ ...action.payload ]
            }; 

        default:
            return state;
    }
};