import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { proximasactEmpiezaListado } from '../../actions/actividades.actions';
import { ListaItemProximaAct } from './ListaItemProximaAct';

export const ProximasAct = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(proximasactEmpiezaListado());
    }, [dispatch]);

    const { actividades } = useSelector(state => state.actividades);

    if (actividades.length === 0) {
        return (
            <div className='parrafo'>
                De momento no hay ninguna actividad planificada a futuro
            </div>
        );
    };

    return (
        <>
            {
                actividades.map(actividad => (
                    <ListaItemProximaAct
                        key={actividad.id}
                        data={actividad}
                    />
                ))
            }
        </>
    )

}

