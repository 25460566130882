import React, { useEffect } from 'react';
import { Medios } from './Medios';
import { TipoMedio } from './TipoMedio';
import { eventoGA } from '../../helpers/eventoGA';

export const MediosScreen = () => {
    useEffect(() => {
        eventoGA('Menu_medios', 'Menu_medios');
    }, []);

    return (
        <>
            <div className="webAcc__content-titulo">
                <h1 className="parrafo__h1" >
                    Medios
                </h1>
                <hr />
            </div>
            <div className="webAcc__content-bloques">
                <TipoMedio />
                <Medios />
            </div>
        </>
    );
};