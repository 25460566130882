import { alertaError } from '../helpers/alertas';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

/**
 * En este fichero vamos a registrar las acciones que se corresponden a los objetos
 * relacionados con el apartado CONFERENCIAS, es decir, 
 * VIDEOS cuyo booleano "conferencia" === true
 */

/***
 * LISTADO DE EPISODIOS - Se listan los episodios de un programa concreto.
 * */
export const conferenciasEmpiezaListado = () => {

    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('video',
                {
                    key: 'conferencia',
                    data: true
                }
            );
            const body = await resp.json();

            const videos = body.videos;

            // console.log( 'conferenciasEmpiezaListado -> vídeos: ', videos );

            dispatch(conferenciasListado(videos));

        } catch (error) {
            alertaError('conferenciasListado -> ' + error);
        };
    }

};


// Esta no se exporta porque se utiliza en este fichero
// Esta no se exporta porque se utiliza en este fichero
const conferenciasListado = (videos) => ({
    type: types.conferenciasListado,
    payload: videos
});