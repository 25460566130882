/***
 * Reducer (función) que manejará el estado de autorización del usuario
 * Si no hay usuario autenticado, estate estará vacío
 * Si existe usuario autenticado, el state será un objeto:
 * { uid:'sdfsdfds', name:'Patricia' }
*/

import { types } from "../types/types";

const initialState = {
    uid: '',
    name: '',
    renovandoToken: false,
    isLogged: false
};

export const authReducer = ( state = initialState, action ) =>{

    // console.log('authReducer action: ', action);
    // console.log('authReducer state: ', state);

    switch ( action.type ) {

        case types.authLogin:
            return {
                ...state,
                uid: action.payload.uid,
                name: action.payload.displayName,
                isLogged: true
            };

        case types.authRenovandoToken:
            return {
                ...state,
                renovandoToken: false
            }

        case types.authLogout:
            return {
                isLogged: false
            };    

        default:
            return state;
    };
};