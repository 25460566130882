import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tiposMedioEmpiezaListado } from '../../actions/medios.actions';
import { TipoMedioItem } from './TipoMedioItem';

export const TipoMedio = () => {

    const dispatch = useDispatch();

    const { tiposMedio } = useSelector(state => state.medios);

    useEffect(() => {
        if (tiposMedio.length === 0) {
            dispatch(tiposMedioEmpiezaListado());
        }
    }, [dispatch, tiposMedio.length])

    return (
        <div className="bloque__columna">
            {
                tiposMedio &&
                tiposMedio.map(tipoMedio => (
                    <TipoMedioItem
                        key={tipoMedio.id}
                        data={tipoMedio}
                    />
                ))
            }
        </div>
    );
};
