import Swal from 'sweetalert2';

export const alertaConfirmacion = ( texto, funcion) => {
    Swal
        .fire({
            title: '¿Estás segura?',
            text: texto,
            icon: 'question',
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#618c61',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#ff6f6f'

        })
        .then( res => {
            if (res.isConfirmed){
                // dispatch( funcion() );
            }
            return true;
        })
        .catch( error => {
            console.log('Error en alertaConfirmacion');
            return false;
        });
};

export const alertaError = ( texto ) => {
    return Swal.fire({
        title: 'Error',
        text: texto,
        icon: 'error'
    });
};

export const alertaOk = ( texto ) => {
    return popup.fire({
        title: texto,
        icon: 'success'
    });
};

export const alertaInfo = ( texto ) => {
    return Swal.fire({
        title: 'Información',
        text: texto,
        icon: 'info'
    });
};

const popup = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});