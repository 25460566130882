import { jsonRichTextToText } from '../../helpers/jsonRichText';
import { RichTextVisor } from './RichText/RichTextVisor';

export const Parrafo = ({ data = '' }) => {

    const { descripcion, situacionImagen, fich } = data;

    const textoJson = JSON.parse( jsonRichTextToText( descripcion ) );

    let value = [];
    value.push(textoJson);

    switch ( situacionImagen ){
        case 'D':
            return (
                <div className="parrafo__container">
                    <span className="parrafo">
                        <RichTextVisor value = { value }/>
                    </span>
                    <img 
                        src= { fich }
                        className="parrafo__imagen"
                        alt="Imagen de párrafo"
                        />
                </div>
            );
            
            case 'I':
                return (
                    <div className="parrafo__container">
                    <img 
                        src= { fich }
                        className="parrafo__imagen"
                        alt="Imagen de párrafo"
                    />
                    <span className="parrafo">
                        {/* { descripcion } */}
                        <RichTextVisor value = { value }/>
                    </span>
                </div>
            );

        default:
            return (
                <div className="parrafo__container">
                    <span className="parrafo">
                        {/* { descripcion } */}
                        <RichTextVisor value = { value }/>
                    </span>
                </div>
            )
    } 

};
