import React from 'react';
import { useSelector } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import editSolid from '@iconify/icons-la/edit-solid';

import { SocialBar } from '../ui/SocialBar';


export const Header = () => {

    // const dispatch = useDispatch();
    const history = useHistory();
    
    const { name } = useSelector( state => state.auth ); 
    
    const handleNavegarPrivado = () =>{
        const path = history.location.pathname.slice(1);
        // console.log( 'history:', path );
        // console.log( 'edit:', '/app/' + path );

        history.replace('/app/' + path);
    };

    return (
        <header className="header__main-content fondo-grey-light">
            <Link to="/" className="link ml-3">
                anacarrascoconde.com
            </Link>
                <div className="header__main-content fondo-grey-light">
                    <SocialBar />
                    {name && (
                        <>
                            <span className="header__nombre">{ name }</span>
                            <button 
                                className="boton"
                                onClick={ handleNavegarPrivado }
                            >
                                <Icon icon={editSolid} color="black" width="2em" height="2em"/>
                            </button>
                        </>
                    )}
                </div> 
        </header>
    );
};
