import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Publicaciones } from './Publicaciones';
import { TipoPublicacion } from './TipoPublicacion';
import { TiposItinerarios } from './TiposItinerarios';
import { eventoGA } from '../../helpers/eventoGA';

export const PublicacionesScreen = () => {

    const { tipoPublicSeleccionado } = useSelector(state => state.publicaciones);

    useEffect(() => {
        eventoGA('Menu_publicaciones', 'Menu_publicaciones');
    }, []);

    return (
        <>
            <div className="webAcc__content-titulo">
                <h1 className="parrafo__h1" >
                    Publicaciones
                </h1>
                <hr />
            </div>
            <div className="webAcc__content-bloques">
                <TipoPublicacion />
                {
                    tipoPublicSeleccionado && tipoPublicSeleccionado.itinerario
                        ? <TiposItinerarios />
                        : <Publicaciones />
                }
            </div>
        </>
    );
};