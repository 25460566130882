import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tiposMedioEntrEmpiezaListado } from '../../actions/entrevistas.actions';
import { TipoMedioEntrItem } from './TipoMedioEntrItem';

export const TipoMedioEntr = () => {

    const dispatch = useDispatch();

    const { tiposMedioEntr } = useSelector(state => state.entrevistas);

    useEffect(() => {
        if (tiposMedioEntr.length === 0) {
            dispatch(tiposMedioEntrEmpiezaListado());
        }
    }, [dispatch, tiposMedioEntr.length])

    return (
        <div className="bloque__columna">
            {
                tiposMedioEntr &&
                tiposMedioEntr.map(tipoMedioEntr => (
                    <TipoMedioEntrItem
                        key={tipoMedioEntr.id}
                        data={tipoMedioEntr}
                    />
                ))
            }
        </div>
    );
};
