import { types } from '../types/types';

const initialState = {
    bio: []
};

export const bioReducer = ( state = initialState, action ) => {

    // console.log('bioReducer action: ', action);
    // console.log('bioReducer state: ', state);

    switch ( action.type) {

        case types.bioListado:
            return {
                ...state,
                bio: action.payload
            };

        default:
            return state;
    }
};