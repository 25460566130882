// Las acciones del User Interface serán síncronas

import { types } from '../types/types';

export const uiStartLoading = () => ({ type: types.uiStartLoading });

export const uiFinishLoading = () => ({ type: types.uiFinishLoading });

export const uiAbrirModal = () => ({ type: types.uiModalAbierta });

export const uiCerrarModal = () => ({ type: types.uiModalCerrada });

// export const uiRespuestaCerrandoModal = ( respuestaModal ) => ({ 
//     type: types.uiFilaSeleccionada,
//     payload: respuestaModal
// });
export const uiFilaParaBorrar = (id) => ({
    type: types.uiFilaParaBorrar,
    payload: id
});

export const uiBuscaTexto = (texto) => ({
    type: types.uiBuscaTexto,
    payload: texto
});

export const uiOpcionTituloFiltro = (valor) => ({
    type: types.uiOpcionTituloFiltro,
    payload: valor
});

export const uiIpUser = (valor) => ({
    type: types.uiIpUser,
    payload: valor
});