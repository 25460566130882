import React from 'react';
import { es } from 'date-fns/locale';
import { Icon } from '@iconify/react';
import playCircle from '@iconify/icons-la/play-circle';
import volumeMute from '@iconify/icons-la/volume-mute';
import { InlineIcon } from '@iconify/react';
import iconoLink from '@iconify/icons-la/external-link-alt';
import { useDispatch } from 'react-redux';
import { episodioSeleccionado } from '../../actions/medios.actions';
import { AudioPlayer } from '../ui/AudioPlayer';
import { uiAbrirModal } from '../../actions/ui.actions';
import { dameImg } from '../../helpers/dameImg';
import { format, isAfter, parseISO, subDays } from 'date-fns';
import { eventoGA } from '../../helpers/eventoGA';

export const GaleriaItemRadio = (data = {}) => {
    const dispatch = useDispatch();

    // console.log( 'EpisodiosItem -> EPISODIO: ', data.data.titulo, data );

    const hace30dias = subDays(new Date(), 30);
    const fechaOnAir = (format(parseISO(data.data.fechaOnAir), "dd/MM/yyyy", { locale: es }));

    const handleClick = () => {
        dispatch(episodioSeleccionado(data.data));
        dispatch(uiAbrirModal());
        estadistica();
        // console.log('Pulsamos PLAY, episodio', data.data);
    }

    const estadistica = () => {
        eventoGA('enlace', 'radio_' + data.data.titulo);
    };

    return (
        <div
            className="galeria__item"
        >
            {/* Si hay url es que el programa está en la web de la cadena ser. 
                Si no, cargaremos el fichero que tenemos de audio en cloudinary
                Si por lo que sea no hay tampoco url de cloudinary, mostraremos un icono de "sin sonido"
            */}
            {isAfter(parseISO(data.data.actualizado), hace30dias) &&
                <div className='galeria__item-img-nuevo animated flash'>
                    <img
                        src={dameImg('./nuevo.png').default}
                        alt='Nuevo'
                    />
                </div>
            }
            {
                data.data.url && (<a
                    onClick={estadistica}
                    href={data.data.url}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                >
                    <img
                        className="episodio__img"
                        src={dameImg(`./audio01.png`).default} alt='Reproducir audio' />
                    {/* <Icon icon={ playCircle } color="black" width="3em" height="3em"/> */}
                </a>
                )}

            {
                data.data.fich &&
                <button
                    className="boton-icono"
                    onClick={handleClick}
                >
                    <Icon icon={playCircle} color="black" width="3em" height="3em" />
                </button>
            }

            {(!data.data.url && !data.data.fich) && (
                <Icon icon={volumeMute} color="black" width="3em" height="3em" />
            )
            }
            <div className="parrafo__galeria-item-titulo">
                {data.data.titulo}
            </div>
            <div className="parrafo__galeria-item-info">
                {data.data.programa.descripcion} ({fechaOnAir})
            </div>
            <div className="parrafo__galeria-item-link">
                <InlineIcon icon={iconoLink} color="black" width="1.3em" height="1.3em" />
                <a
                    onClick={estadistica}
                    href={data.data.url}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                >
                    Escuchar programa...
                </a>
            </div>
            <AudioPlayer />
        </div>
    );
};
