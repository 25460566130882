import ReactGA from 'react-ga4'; // Google Analytics

export const eventoGA = (categoria, accion, etiqueta) => {
    // console.log('eventoGA -> process.env.NODE_ENV: ', process.env.NODE_ENV);
    // console.log('eventoGA -> categoria: ', categoria);
    // console.log('eventoGA -> accion: ', accion);
    // console.log('eventoGA -> etiqueta: ', etiqueta);

    if (process.env.NODE_ENV === 'development') {
        return false;
    };

    ReactGA.event({
        category: categoria,
        action: accion,
        label: etiqueta
    });
    return true;

};