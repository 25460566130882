import { types } from '../types/types';

const initialState = {
    tiposMedio: [],
    tipoMedSeleccionado: null,
    programas: [],
    progSeleccionado: null,
    episodios: [],
    episodioSeleccionado: null,
    videos: [],
    prensa: [],
};

export const mediosReducer = ( state = initialState, action ) => {

    // console.log('mediosReducer action: ', action);
    // console.log('mediosReducer state: ', state);

    switch ( action.type) {

        case types.tiposMedioListado:
            return{
                ...state,
                tiposMedio: [ ...action.payload ]
            };

        case types.tipoMedioSeleccionado:
            return{
                ...state,
                tipoMedSeleccionado: action.payload
            };

        case types.programasListado:
            return{
                ...state,
                programas: [ ...action.payload ]
            };

        case types.programaSeleccionado:
            return{
                ...state,
                progSeleccionado: action.payload
            };

        case types.episodiosListado:
            return{
                ...state,
                episodios: [ ...action.payload ]
            };
    
        case types.episodioSeleccionado:
            return{
                ...state,
                episodioSeleccionado: action.payload
            };

        case types.videosListado:
            return{
                ...state,
                videos: [ ...action.payload ]
            };            

        case types.prensaListado:
            return{
                ...state,
                prensa: [ ...action.payload ]
            };            

        case types.conferenciasListado:
            return{
                ...state,
                videos: [ ...action.payload ]
            };            

        default:
            return state;
    }
};