import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Galeria } from '../ui/Galeria';
import { TituloConFiltro } from '../ui/TituloConFiltro';
import { TituloSinFiltro } from '../ui/TituloSinFiltro';
import { publicacionesEmpiezaListado } from '../../actions/publicaciones.actions';
import { itinerarioEmpiezaListado, tipoItinerarioEmpiezaListado } from '../../actions/itinerario.actions';

export const Publicaciones = () => {

    const dispatch = useDispatch();

    const { tipoPublicSeleccionado, libros, revistas, articulos } = useSelector(state => state.publicaciones);
    const { textoBusqueda } = useSelector(state => state.ui);

    const [listadoArticulos, setListadoArticulos] = useState(articulos);
    const [listadoLibros, setlistadoLibros] = useState(libros);

    useEffect(() => {
        if (textoBusqueda.length > 2) {
            const listado1 = articulos.filter(articulo => (articulo.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = articulos.filter(articulo => (articulo.tituloLibro.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado3 = articulos.filter(articulo => (articulo.editorial.nombre.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));

            setListadoArticulos(listado1.concat(listado2, listado3));
        } else {
            setListadoArticulos(articulos);
        }
    }, [textoBusqueda, articulos]);

    useEffect(() => {
        if (textoBusqueda.length > 2) {
            const listado1 = libros.filter(libro => (libro.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = libros.filter(libro => (libro.editorial.nombre.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));

            setlistadoLibros(listado1.concat(listado2));
        } else {
            setlistadoLibros(libros);
        }
    }, [textoBusqueda, libros]);

    const librosColectivos = listadoLibros.filter(libro => (libro.colectivo === true));
    const librosAna = listadoLibros.filter(libro => (libro.colectivo === false));

    useEffect(() => {
        if (tipoPublicSeleccionado) {
            if (!tipoPublicSeleccionado.itinerario)
                dispatch(publicacionesEmpiezaListado(tipoPublicSeleccionado))
        }
    }, [tipoPublicSeleccionado]);

    if (tipoPublicSeleccionado) {
        switch (tipoPublicSeleccionado.id) {
            case process.env.REACT_APP_PUBL_LIBROS:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoPublicSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoPublicSeleccionado.id}
                            elementos={librosAna}
                        />
                        <TituloSinFiltro
                            titulo={"Libros colectivos"}
                        />
                        <Galeria
                            tipoGaleria={tipoPublicSeleccionado.id}
                            elementos={librosColectivos}
                        />

                    </div>
                )

            case process.env.REACT_APP_PUBL_REVISTAS:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoPublicSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoPublicSeleccionado.id}
                            elementos={revistas}
                        />
                    </div>
                )
            case process.env.REACT_APP_PUBL_ARTICULOS:
            case process.env.REACT_APP_PUBL_CAPITULOS:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoPublicSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoPublicSeleccionado.id}
                            elementos={listadoArticulos}
                        />
                    </div>
                )
            default:
                return (
                    <>
                        <p>Sin Datos</p>
                    </>
                )
        }
    }
    return (
        <>
            <p>Sin Datos</p>
        </>
    )
}