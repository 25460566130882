/**
 *  Este helper va a definir dos formas de hacer fetch a las peticiones hhtp:
 *  unas llamadas irán con token y otras irán sin token.
 */

import { dameLocalStorage } from "./persistirLocalStorage";

const baseUrl = process.env.REACT_APP_API_URL;

// console.log('BaseURL: ', baseUrl);

export const fetchSinToken = (endpoint, data, method = 'GET') => {

    // Si el método es GET y lleva parámetros en la URL me llegará un objeto como este:
    // { key:'id_medio',
    //   data:id_medio } );
    // que indicará la clave y el valor que hay que concatenar a la url

    // console.log('fetchSinToken -> data:', data);

    var url = `${baseUrl}/${endpoint}`; // genero la url respectiva

    url = url + dameParamsGet();

    if (method === 'GET') {
        if (data) {
            url = url + (`&${data.key}=${data.data}`);
            if (process.env.REACT_APP_TRAZA === 'true') {
                console.log('fetchSinToken -> URL CON PARAMS:', url);
            }
        } else {
            if (process.env.REACT_APP_TRAZA === 'true') {
                console.log('fetchSinToken -> URL SIN PARAMS:', url);
            }
        }
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    };
};

export const fetchSinTokenDataArray = (endpoint, data = [], method = 'GET') => {

    // En este método llegará un array de varios parámetros para la URL de este modo:
    // [{ key:'id_medio',
    //    data:id_medio }
    //    { key:'entrevista',
    //    data:entrevista } );

    // console.log('fetchSinTokenDataArray -> data:', data );

    var url = `${baseUrl}/${endpoint}`; // genero la url respectiva

    url = url + dameParamsGet();

    // console.log('fetchSinTokenDataArray -> Se va a lanzar la siguiente URL:', url, ' con los parámetros: ', data);

    if (method === 'GET') {
        if (data) {
            url = url + `&`;
            data.forEach(parametro => {
                url = url + (`${parametro.key}=${parametro.data}&`);
            });
            // Quitamos el último '&'
            url = url.substring(0, (url.length - 1));
            if (process.env.REACT_APP_TRAZA === 'true') {
                console.log('fetchSinTokenDataArray -> URL CON PARAMS:', url);
            }
        } else {
            if (process.env.REACT_APP_TRAZA === 'true') {
                console.log('fetchSinTokenDataArray -> URL SIN PARAMS:', url);
            }
        }
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    };
};

export const fetchConToken = (endpoint, method = 'GET', data) => {
    // console.log('*********** fetchConToken *************');
    // console.log('method:', method);
    // console.log('data:', data);
    const url = `${baseUrl}/${endpoint}`; // genero la url respectiva

    const token = dameLocalStorage('token') || '';
    // console.log('token:', token);

    if (process.env.REACT_APP_TRAZA === 'true') {
        console.log('fetchConToken -> Se va a lanzar la siguiente URL:', url, ' con los parámetros: ', data);
    }

    if (method === 'DELETE') {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        });
    } else if (method === 'GET') {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        });
    };
};

export const dameParamsGet = () => {
    const desde = process.env.REACT_APP_GET_DESDE;
    const hasta = process.env.REACT_APP_GET_LIMITE;
    // console.log('desde',desde);
    // console.log('hasta', hasta);
    // return `?desde=${process.env.GET_DESDE}&limite=${process.env.GET_LIMITE}`;
    return `?desde=${desde}&limite=${hasta}&activo=true`;
}