import React from 'react';
import { InlineIcon } from '@iconify/react';
import iconoLink from '@iconify/icons-la/external-link-alt';
import { format, parseISO, isAfter, subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import { dameImg } from '../../helpers/dameImg';
import { eventoGA } from '../../helpers/eventoGA';

export const GaleriaItemVideo = (data = {}) => {
    var img = '';

    const hace30dias = subDays(new Date(), 30);

    data.data.fich
        ? img = data.data.fich
        : img = process.env.REACT_APP_NO_IMAGE

    const estadistica = () => {
        eventoGA('enlace', 'video_' + data.data.titulo);
    };

    return (
        <div className="galeria__item">
            {isAfter(parseISO(data.data.actualizado), hace30dias) &&
                <div className='galeria__item-img-nuevo animated flash'>
                    <img
                        src={dameImg('./nuevo.png').default}
                        alt='Nuevo'
                    />
                </div>
            }
            <a
                onClick={estadistica}
                href={data.data.url}
                target="_blank"
                rel="noreferrer"
                className="link"
            >
                <img
                    className="galeria__item-img"
                    src={img}
                    alt={data.data.titulo}
                />
            </a>
            <div className="parrafo__galeria-item-titulo">
                {data.data.titulo}
            </div>
            <div className="parrafo__galeria-item-info">
                {data.data.organizador.nombre} ({(format(parseISO(data.data.fecha), "dd/MM/yyyy", { locale: es }))})
            </div>
            <div className="parrafo__galeria-item-info">
                {data.data.programa.descripcion}
            </div>
            <div className="parrafo__galeria-item-link">
                <InlineIcon icon={iconoLink} color="black" width="1.3em" height="1.3em" />
                <a
                    onClick={estadistica}
                    href={data.data.url}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                >
                    Ver vídeo...
                </a>
            </div>

        </div>
    )
}
