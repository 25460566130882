export const jsonRichTextToText = ( jsonText ) => {
    const texto1 = jsonText.replaceAll('"', '\\"');
    const texto2 = texto1.replaceAll('\'', '"');

    return texto2;
};

export const textRichTextToJson = ( plainText ) => {
    const texto1 = plainText.replaceAll('"', '\'');
    const texto2 = texto1.replaceAll('\\\'', '\\"');
    
    return texto2;
};