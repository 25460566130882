import React from 'react'
import { GaleriaItemLibros } from '../03-publicaciones/GaleriaItemLibros';
import { GaleriaItemRevistas } from '../03-publicaciones/GaleriaItemRevistas';
import { GaleriaItemArticulos } from '../03-publicaciones/GaleriaItemArticulos';
import { GaleriaItemVideo } from '../04-medios/GaleriaItemVideo';
import { GaleriaItemPrensa } from '../04-medios/GaleriaItemPrensa';
import { GaleriaItemRadio } from '../04-medios/GaleriaItemRadio';
import { GaleriaItemPremios } from '../02-premios/GaleriaItemPremios';

export const Galeria = ({ tipoGaleria = '', elementos = [] }) => {
    // console.log('Galería -> elementos', elementos);
    // console.log('Galeria -> tipoGaleria: ', tipoGaleria);

    if (elementos.length === 0) {
        return (
            <div className='parrafo'>
                No hay información en este momento para las opciones seleccionadas
            </div>
        );
    }

    switch (tipoGaleria) {
        case process.env.REACT_APP_PUBL_LIBROS:
            return (
                <div className='galeria__main-content'>
                    {
                        elementos.map(elemento => (
                            <GaleriaItemLibros
                                key={elemento.id}
                                data={elemento}
                            />
                        ))
                    }
                </div>
            );

        case process.env.REACT_APP_PUBL_REVISTAS:
            return (
                <div className='galeria__main-content'>
                    {
                        elementos.map(elemento => (
                            <GaleriaItemRevistas
                                key={elemento.id}
                                data={elemento}
                            />
                        ))
                    }
                </div>
            );

        case process.env.REACT_APP_PUBL_ARTICULOS:
        case process.env.REACT_APP_PUBL_CAPITULOS:
            return (
                <div className='galeria__main-content'>
                    {
                        elementos.map(elemento => (
                            <GaleriaItemArticulos
                                key={elemento.id}
                                data={elemento}
                            />
                        ))
                    }
                </div>
            );

        case process.env.REACT_APP_MED_VIDEO:
            return (
                <div className='galeria__main-content'>
                    {
                        elementos.map(elemento => (
                            <GaleriaItemVideo
                                key={elemento.id}
                                data={elemento}
                            />
                        ))
                    }
                </div>
            );

        case process.env.REACT_APP_MED_PRENSA:
            return (
                <div className='galeria__main-content'>
                    {
                        elementos.map(elemento => (
                            <GaleriaItemPrensa
                                key={elemento.id}
                                data={elemento}
                            />
                        ))
                    }
                </div>
            );

        case process.env.REACT_APP_MED_RADIO:
            return (
                <div className='galeria__main-content'>
                    {
                        elementos.map(elemento => (
                            <GaleriaItemRadio
                                key={elemento.id}
                                data={elemento}
                            />
                        ))
                    }
                </div>
            );

        case process.env.REACT_APP_PREMIOS:
            return (
                <div className='galeria__main-content'>
                    {
                        elementos.map(elemento => (
                            <GaleriaItemPremios
                                key={elemento.id}
                                data={elemento}
                            />
                        ))
                    }
                </div>
            )


        default: // ACTIVIDADES
            return (
                <div className='galeria__main-content'>
                    Sin galería
                </div>
            )
        // break;
    }
}
