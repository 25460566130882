import validator from 'validator';

export const validateForm = ( formValues ) => {

    const { email, password } = formValues;
    let errors = {};

    if ( validator.isEmpty( email ) ){
        errors.email = 'El email es obligatorio';
    } else if ( !validator.isEmail( email ) ){
        errors.email = 'El email no es válido';
    };
    
    if ( validator.isEmpty( password ) ){
        errors.password = 'La password es obligatoria';
//    } 
    // else if ( !validator.isStrongPassword ( password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 } ) ){
    // else if ( !validator.isStrongPassword ( password, { minLength: 8 } ) ){
    //     errors.password = 'La password debe tener al menos: 8 caracteres';
    };

    return errors;
};