/***
 * Función que creará el store de mi aplicación
 * Se manejan funciones de redux que me permiten crear un objeto de reducers
 * que serán los que vaya manejando mi aplicación.
 * **
 * Se instala el paquete npm install --save redux-thunk para implementar los
 * middleware que invoquen a APIs
*/

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { rootReducer } from '../reducers/rootReducer';

// Funciones para mantener el store en el localStorage  
// export const loadState = () => {
//     try {
//         const serializedData = localStorage.getItem('state');
//         if (serializedData === null){
//             // Si no existe el state en el local storage devolvemos undefined para que cargue el state inicial 
//             // que hayamos definido
//             return undefined;
//         };
//         // Si encontramos con exito nuestro storage lo devolvemos.
//         return JSON.parse(serializedData);
//     } catch (error) {
//         // Si ocurre algun error, devuelvo undefined para cargar el state inicial.
//         return undefined; 
//     }
// };

// export const saveState = ( state ) => {
//     try {
//         // console.log( 'State que guardamos en el localStorage:', state );
//         let serializedData = JSON.stringify(state)
//         localStorage.setItem('state', serializedData)
//     } catch (error) {
//         console.log( 'ERROR guardando el Store', error );
//     }
// }
  
// const initialData = loadState();

const composeEnhancers = (
        typeof window !== 'undefined' 
        && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) 
        || compose;

export const store = createStore(
    rootReducer,
    // initialData,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);

// store.subscribe( function () {
//     saveState(store.getState())
//   });
