import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export const Footer = () => {
    const hoy = new Date();
    const anyo = format( hoy, "yyyy", {locale: es } );

    return (
        <footer className="footer fondo-grey-dark">
            <div className="footer__item">
                <small> ©Ana Carrasco Conde { anyo } </small>
            </div>
        </footer>
    );
};
