import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Galeria } from '../ui/Galeria';
import { TituloConFiltro } from '../ui/TituloConFiltro';
import { episodiosEntrEmpiezaListado } from '../../actions/entrevistas.actions';

export const Entrevistas = () => {

    const dispatch = useDispatch();

    const { tipoMedEntrSeleccionado, videosEntr, prensaEntr, episodiosEntr } = useSelector(state => state.entrevistas);
    const { textoBusqueda } = useSelector(state => state.ui);

    const [listadoVideos, setListadoVideos] = useState(videosEntr);
    const [listadoPrensa, setListadoPrensa] = useState(prensaEntr);
    const [listadoRadio, setListadoRadio] = useState(episodiosEntr);

    useEffect(() => {
        if (videosEntr && textoBusqueda.length > 2) {
            const listado1 = videosEntr.filter(video => (video.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = videosEntr.filter(video => (video.programa.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado3 = videosEntr.filter(video => (video.organizador.nombre.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));

            setListadoVideos(listado1.concat(listado2, listado3));
        } else {
            setListadoVideos(videosEntr);
        }
    }, [textoBusqueda, videosEntr]);

    useEffect(() => {
        if (prensaEntr && textoBusqueda.length > 2) {
            const listado1 = prensaEntr.filter(prens => (prens.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = prensaEntr.filter(prens => (prens.programa.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            setListadoPrensa(listado1.concat(listado2));
        } else {
            setListadoPrensa(prensaEntr);
        }
    }, [textoBusqueda, prensaEntr]);

    useEffect(() => {
        if (episodiosEntr && textoBusqueda.length > 2) {
            const listado1 = episodiosEntr.filter(episodio => (episodio.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = episodiosEntr.filter(episodio => (episodio.programa.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));

            setListadoRadio(listado1.concat(listado2));
        } else {
            setListadoRadio(episodiosEntr);
        }
    }, [textoBusqueda, episodiosEntr]);

    useEffect(() => {
        if (tipoMedEntrSeleccionado) {
            console.log('Entrevistas -> tipoMedEntrSeleccionado: ', tipoMedEntrSeleccionado);
            dispatch(episodiosEntrEmpiezaListado({ id_medio: tipoMedEntrSeleccionado.id }));
        }
    }, [tipoMedEntrSeleccionado]);

    if (tipoMedEntrSeleccionado) {
        switch (tipoMedEntrSeleccionado.id) {
            case process.env.REACT_APP_MED_RADIO:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoMedEntrSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoMedEntrSeleccionado.id}
                            elementos={listadoRadio}
                        />
                    </div>
                );
            case process.env.REACT_APP_MED_PRENSA:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoMedEntrSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoMedEntrSeleccionado.id}
                            elementos={listadoPrensa}
                        />
                    </div>
                );
            case process.env.REACT_APP_MED_VIDEO:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoMedEntrSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoMedEntrSeleccionado.id}
                            elementos={listadoVideos}
                        />
                    </div>
                );
            default:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo='Nada recuperado'
                        />
                    </div>
                );
        }
    }
    return (
        <>
            <Galeria
            />
        </>
    );
}
