import { alertaError } from "../helpers/alertas";
import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import { uiFilaParaBorrar, uiFinishLoading, uiStartLoading } from "./ui.actions";

export const bioEmpiezaListado = () => {
    const url = 'bio';

    return async (dispatch) => {
        try{
            dispatch( uiStartLoading());

            const resp = await fetchSinToken( url );
            const body = await resp.json();

            const bio = body.bios;
            
            dispatch( bioListado( bio ) );

            dispatch( uiFinishLoading());
        }catch( error ){
            alertaError( error );
        };
    }
};

// Esta no se exporta
const bioListado = ( bio ) => ({
    type: types.bioListado,
    payload: bio

});

export const bioDarBaja = ( id ) => {
    const url = `bio/${ id }`;
    // console.log( 'bioDarBaja -> url: ', url );

    return async (dispatch) => {
            // console.log( 'Entramos en la función asíncrona' );
        try{
            dispatch( uiStartLoading());
            const resp = await fetchConToken( url, 'DELETE' );
            const body = await resp.json();
            // console.log( 'bioDarBaja -> body: ', body );

            if( body.ok ) {
                dispatch( uiFilaParaBorrar( null ) );
    
                dispatch (bioEmpiezaListado());
            }

            dispatch( uiFinishLoading());
        }catch( error ){
            alertaError( error );
        };
    }
};
