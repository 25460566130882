import { alertaError } from '../helpers/alertas';
import { fetchSinToken, fetchSinTokenDataArray } from '../helpers/fetch';
import { types } from '../types/types';

/**
 * En este fichero vamos a registrar las acciones que se corresponden a los objetos
 * relacionados con el apartado MEDIOS, es decir:
 *  - Tipos de medio
 *  - Medios
 *  - Programas
 */

/***
 * LISTADO DE TIPOS DE MEDIOS 
 * */  
 export const tiposMedioEmpiezaListado = () => {
    // console.log( 'EMPEZAMOS tiposMedioEmpiezaListado' );
    return async ( dispatch ) =>{
        try{
            const resp = await fetchSinToken( 'tiposMedio' ); // Petición GET por defecto. No se indica
            const body = await resp.json();

            const tiposMedio = body.tiposMedio;

            dispatch( tiposMedioListado( tiposMedio ) );
            dispatch( tipoMedioSeleccionado( tiposMedio[0] ) );
            // console.log('tiposMedioEmpiezaListado -> programasEmpiezaListado -> id_medio: ', tiposMedio[0].id);
            // dispatch( programasEmpiezaListado ( {
            //                                         'id_medio':tiposMedio[0].id, 
            //                                         'entrevista': false
            //                                     } ) );

        }catch( error ){
            alertaError( 'tiposMedioEmpiezaListado -> ' + error );
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const tiposMedioListado = ( tiposMedio ) => ({
    type: types.tiposMedioListado,
    payload: tiposMedio
});

export const tipoMedioSeleccionado = ( tiposMedio ) => ({
    type: types.tipoMedioSeleccionado,
    payload: tiposMedio
});

/***
 * LISTADO DE PROGRAMAS - Se listan los programas de un medio concreto.
 * */  
 export const programasEmpiezaListado = ( data = {} )  => {
     
    // console.log('programasEmpiezaListado -> data: ', data);
    const { id_medio } = data;
     
    // console.log('programasEmpiezaListado -> id_medio: ', id_medio);

    return async ( dispatch ) =>{
        try{
            const resp = await fetchSinTokenDataArray( 'programa', 
                [{ key:'id_medio',
                  data:id_medio },
                { key:'medio',
                  data:true }]
                ); 
            const body = await resp.json();
            
            const programas = body.programas;

            dispatch( programasListado( programas ) );
            dispatch( programaSeleccionado( programas[0] ) ); // Seleccionamos el primer programa del array
            dispatch( episodiosEmpiezaListado ( programas[0].id ) );
        }catch( error ){
            alertaError( 'programasEmpiezaListado -> ' + error );
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const programasListado = ( programas ) => ({
    type: types.programasListado,
    payload: programas
});

export const programaSeleccionado = ( programa ) => ({
    type: types.programaSeleccionado,
    payload: programa
});

/***
 * LISTADO DE EPISODIOS - Se listan los episodios de un programa concreto.
 * CAMBIO 11/04/2024 - listamos según el id_medio y no por el programa
 * */  
    
export const episodiosEmpiezaListado = ( {id_medio} )  => {
    switch (id_medio) {
        case process.env.REACT_APP_MED_RADIO:
            return async ( dispatch ) =>{
                try{
                    const resp = await fetchSinTokenDataArray( 'episodio', 
                        [
                            { key:'activo', data:true },
                            { key: 'entrevista',data: false }
                        ] 
                     ); 
                    const body = await resp.json();
                    
                    const episodios = body.episodios;
        
                    dispatch( episodiosListado( episodios ) );
        
                }catch( error ){
                    alertaError( 'episodiosEmpiezaListado -> ' + error );
                };
            }

        case process.env.REACT_APP_MED_VIDEO:
            return async ( dispatch ) =>{
                try{
                    const resp = await fetchSinTokenDataArray( 'video',
                        [
                            { key: 'activo', data: true },
                            { key: 'entrevista', data: false }
                        ] 
                    );  
                    const body = await resp.json();
                    
                    const videos = body.videos;
        
                    dispatch( videosListado( videos ) );
        
                }catch( error ){
                    alertaError( 'videosListado -> ' + error );
                };
            }

        case process.env.REACT_APP_MED_PRENSA:
            return async ( dispatch ) =>{
                try{
                    const resp = await fetchSinTokenDataArray( 'prensa',
                        [
                            { key: 'activo', data: true },
                            { key: 'entrevista', data: false }
                        ] 
                     ); 
                    const body = await resp.json();
                    
                    const prensa = body.prensas;
        
                    dispatch( prensaListado( prensa ) );
        
                }catch( error ){
                    alertaError( 'prensaListado -> ' + error );
                };
            }

        default:
            return {};
    }
    
};

// Esta no se exporta porque se utiliza en este fichero
const episodiosListado = ( episodios ) => ({
    type: types.episodiosListado,
    payload: episodios
});

export const episodioSeleccionado = ( episodio ) => ({
    type: types.episodioSeleccionado,
    payload: episodio
});

// Esta no se exporta porque se utiliza en este fichero
const videosListado = ( videos ) => ({
    type: types.videosListado,
    payload: videos
});

// Esta no se exporta porque se utiliza en este fichero
const prensaListado = ( prensa ) => ({
    type: types.prensaListado,
    payload: prensa
});

// // Acción que hace que empiece el proceso de grabación
// // Si se graba correctamente en base de datos, llamo a eventoNuevo (por eso no la exporto)
// export const eventoEmpezandoNuevo = ( evento ) => {
//     return async (dispatch, getState) => {

//         const { uid, name } = getState().auth;

//         try{
//             const resp = await fetchConToken( 'eventos', evento, 'POST' );
//             const body = await resp.json();

//             if ( body.ok ){
//                 evento.id = body.evento.id;
//                 evento.user = {
//                     _id: uid,
//                     name: name
//                 }
                
//                 dispatch( eventoNuevo( evento ) );

//                 alertaOk('Evento creado correctamente');
//             } else {
//                 alertaError( body.msg );
//             }
//         } catch (error){
//             alertaError( error );
//         }
//     }
// }

// const eventoNuevo = ( evento ) => ({
//     type: types.eventoNuevo,
//     payload: evento
// });

// export const eventoSeleccionado = ( evento ) => ({
//     type: types.eventoSeleccionado,
//     payload: evento
// });

// export const eventoLimpiarSeleccionado = () => ( {
//     type: types.eventoLimpiarSeleccionado
// } );



// /***
//  * ACTUALIZACIÓN DE EVENTOS 
//  * */  
//  export const eventoEmpiezaActualizacion = ( evento ) => {
//     return async ( dispatch ) =>{
//         try{
//             console.log('eventoEmpiezaActualizacion', evento);
            
//             const resp = await fetchConToken( `eventos/${ evento.id }`, evento, 'PUT' ); // Petición PUT 
//             const body = await resp.json();

//             if ( body.ok ){

//                 dispatch( eventoActualizado( body.evento ) );

//                 alertaOk('Evento modificado correctamente');

//             } else {
//                 alertaError( body.msg );
//                 console.log('ERROR eventoEmpiezaActualizacion: ', body);
//             };
//         }catch( error ){
//             alertaError( error );
//         };
//     }
// };

// const eventoActualizado = ( evento ) => ({ 
//     type: types.eventoActualizado,
//     payload: evento 
// });

// /***
//  * BORRADO DE EVENTOS 
//  * */  
//  export const eventoEmpiezaBorrado = ( ) => {
//     return async ( dispatch, getState ) =>{

//         const { id } = getState().calendario.eventoActivo;
//         try{
//             const resp = await fetchConToken( `eventos/${ id }`, {}, 'DELETE' );
//             const body = await resp.json();

//             if ( body.ok ){

//                 dispatch( eventoBorrado( ) );
//                 alertaOk('Evento borrado correctamente');

//             } else {
//                 alertaError( body.msg );
//                 console.log('ERROR eventoEmpiezaBorrado: ', body);
//             };
//         }catch( error ){
//             alertaError( error );
//         };
//     }
// };

// const eventoBorrado = ( ) => ({
//     type: types.eventoBorrado
// });