import { formatearItinerarios } from '../../helpers/formatearItinerarios';
import { Galeria } from '../ui/Galeria';
import { TituloSinFiltro } from '../ui/TituloSinFiltro';

export const Itinerarios = (itinerarios) => {

    if (itinerarios.itinerarios.length < 1)
        return <TituloSinFiltro
            titulo='Sin datos que mostrar'
        />;

    const { librosGaleria, articulosGaleria, capitulosGaleria } = formatearItinerarios(itinerarios);

    return (
        <>
            {
                (librosGaleria && librosGaleria.length > 0) &&
                <>
                    <TituloSinFiltro
                        titulo='Libros'
                    />
                    <Galeria
                        tipoGaleria={process.env.REACT_APP_PUBL_LIBROS}
                        elementos={librosGaleria}
                    />
                </>
            }
            {
                (capitulosGaleria && capitulosGaleria.length > 0) &&
                <>
                    <TituloSinFiltro
                        titulo='Capítulos de libro'
                    />
                    <Galeria
                        tipoGaleria={process.env.REACT_APP_PUBL_ARTICULOS}
                        elementos={capitulosGaleria}
                    />
                </>
            }
            {
                (articulosGaleria && articulosGaleria.length > 0) &&
                <>
                    <TituloSinFiltro
                        titulo='Artículos de revista'
                    />
                    <Galeria
                        tipoGaleria={process.env.REACT_APP_PUBL_ARTICULOS}
                        elementos={articulosGaleria}
                    />
                </>
            }
        </>
    )
};