import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';

// Functional Component que va a devolver la ruta al componente que le llama si no está autenticado
// y si no devolverá un redirect a la página raíz
// Este componente se implementa en las rutas que queremos que sean públicas

export const PublicRoute = ({
    isAuthenticated,
    component: Component, // componente lo renombro a mayúscula
    ...rest
}) => {

    return (
        <Route {...rest}
            // Para recuperar las props de component uso el callback y así las recupero
            component={ (props) => (
                ( isAuthenticated )
                    ? <Redirect to="/app" />
                    : <Component { ...props } />
            )}
        />
    );
};

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
};