import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { PrivHomeScreen } from '../components/private/PrivHomeScreen';

import { PrivNavBar } from '../components/private/PrivNavBar';

// Aquí declaro mis rutas privadas
export const PrivateAppRouter = () => {
    return (
        <div className="webAcc__container">
            <PrivNavBar />
            <main className="webAcc__main-content fondo-whitesmoke">
                <Switch>
                    <Route exact path="/app" component={PrivHomeScreen} />
                    {/* <Route exact path="/app/usuarios" component={ PrivUsuariosScreen } />
                    <Route exact path="/app/eventos" component={ PrivEventosScreen } />
                    <Route exact path="/app/medios" component={ TiposMediosCRUD } />
                    <Route exact path="/app/bio" component={ PrivBioScreen } /> */}
                    {/* <Route exact path="/incordiar" component={ IncordiarScreen } />
                    <Route exact path="/publicaciones" component={ PublicacionesScreen } />
                    <Route exact path="/medios" component={ MediosScreen } />
                    <Route exact path="/entrevistas" component={ EntrevistasScreen } />
                    <Route exact path="/actividades" component={ ActividadesScreen } />
                    <Route exact path="/eventos" component={ EventosScreen } />

                    <Route exact path="/buscar" component={ BuscarScreen } /> */}

                    <Redirect to="/app" />
                </Switch>
            </main>
        </div>
    );
};