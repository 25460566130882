import React, { useEffect } from 'react';
import { Conferencias } from './Conferencias';
import { eventoGA } from '../../helpers/eventoGA';

export const ConferenciasScreen = () => {
    useEffect(() => {
        eventoGA('Menu_conferencias', 'Menu_conferencias');
    }, []);

    return (
        <>
            <div className="webAcc__content-titulo">
                <h1 className="parrafo__h1" >
                    Conferencias
                </h1>
                <hr />
            </div>
            <div className="webAcc__content-bloques">
                <Conferencias />
            </div>
        </>
    );
};