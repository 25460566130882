import { useEffect, useState } from 'react';

export const useForm = ( initialState = {}, callback, validateForm ) => {
    // Este custom hook recibe:
        // initialState -> un elemento de formulario como un objeto (campo de texto, selector...) que sirve para inicializar el formulario
        // callback -> función que se dispara al hacer el submit
        // validateForm -> función que se dispara para validar el formulario
    // Además devuelve:
        // values -> los valores
        // handleInputChange -> evento incluido en el onChange de los elementos del formulario
        // handleSubmit -> evento incluido en el onSubmit del formulario
        // reset -> evento para resetear o limpiar el formulario
        // errors -> objeto con los errores que se disparan al validar el formulario

    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false); // va a impedir que se llame al callback al entrar en el form

    const reset = () =>{
        setValues( initialState );
    };

    const handleInputChange = ( event )=>{
        event.persist();
        const { target } = event;
        
        setValues({
            ...values,
            [ target.name ]: target.value // Creo un key con lo que venga en target.name y le pongo de valor el target.value
        });
    };

    const handleSetValues = ( values )=>{
        //console.log('Mis valores son estos:', values);
        setValues({
            ...values
        });
    };

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setIsSubmitting(true);
        //console.log( 'useForm > llamamos a validateForm', values );
        setErrors(validateForm( values ));
        // console.log( 'useForm > errors', errors );
    };
    
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            // console.log( 'useEffect > llamamos al callback!!!!!!!' );
            callback();
        }
    }, [errors]);

    return [ 
            values, 
            handleInputChange, 
            handleSubmit, 
            handleSetValues,
            errors,
            reset
        ];
};
