import { types } from '../types/types';

const initialState = {
    tiposActividad: [],
    tipoActSeleccionado: null,
    actividades: [],
    siguienteActividad: null,
    mostrarPasadas: true
};

export const actividadesReducer = (state = initialState, action) => {

    // console.log('actividadesReducer action: ', action);
    // console.log('actividadesReducer state: ', state);

    switch (action.type) {

        case types.itinerariosListado:
            return {
                ...state,
                tiposActividad: [...action.payload]
            };

        case types.tipoActividadSeleccionado:
            return {
                ...state,
                tipoActSeleccionado: action.payload
            };

        case types.actividadesListado:
            return {
                ...state,
                actividades: action.payload
            };

        case types.siguienteActiv:
            return {
                ...state,
                siguienteActividad: action.payload
            };

        default:
            return state;
    }
};