import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Galeria } from '../ui/Galeria';
import { TituloConFiltro } from '../ui/TituloConFiltro';
import { episodiosEmpiezaListado } from '../../actions/medios.actions';

export const Medios = () => {

    const dispatch = useDispatch();

    const { tipoMedSeleccionado, videos, prensa, episodios } = useSelector(state => state.medios);
    const { textoBusqueda } = useSelector(state => state.ui);

    const [listadoVideos, setListadoVideos] = useState(videos);
    const [listadoPrensa, setListadoPrensa] = useState(prensa);
    const [listadoRadio, setListadoRadio] = useState(episodios);

    // console.log( 'Medios -> tipoMedSeleccionado: ', tipoMedSeleccionado );
    // console.log( 'Medios -> videos: ', videos );
    // console.log( 'Medios -> prensa: ', prensa );

    useEffect(() => {
        if (videos && textoBusqueda.length > 2) {
            const listado1 = videos.filter(video => (video.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = videos.filter(video => (video.programa.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado3 = videos.filter(video => (video.organizador.nombre.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));

            setListadoVideos(listado1.concat(listado2, listado3));
        } else {
            setListadoVideos(videos);
        }
    }, [textoBusqueda, videos]);

    useEffect(() => {
        if (prensa && textoBusqueda.length > 2) {
            const listado1 = prensa.filter(prens => (prens.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = prensa.filter(prens => (prens.programa.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));

            // setListadoPrensa( listado1.concat( listado2, listado3 ) );
            setListadoPrensa(listado1.concat(listado2));
        } else {
            setListadoPrensa(prensa);
        }
    }, [textoBusqueda, prensa]);

    useEffect(() => {
        if (episodios && textoBusqueda.length > 2) {
            const listado1 = episodios.filter(episodio => (episodio.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = episodios.filter(episodio => (episodio.programa.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));

            setListadoRadio(listado1.concat(listado2));
        } else {
            setListadoRadio(episodios);
        }
    }, [textoBusqueda, episodios]);

    useEffect(() => {
        if (tipoMedSeleccionado) {
            dispatch(episodiosEmpiezaListado({ id_medio: tipoMedSeleccionado.id }));
        }
    }, [tipoMedSeleccionado]);

    if (tipoMedSeleccionado) {
        switch (tipoMedSeleccionado.id) {
            case process.env.REACT_APP_MED_RADIO:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoMedSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoMedSeleccionado.id}
                            elementos={listadoRadio}
                        />
                    </div>
                );
            case process.env.REACT_APP_MED_PRENSA:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoMedSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoMedSeleccionado.id}
                            elementos={listadoPrensa}
                        />
                    </div>
                );
            case process.env.REACT_APP_MED_VIDEO:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo={tipoMedSeleccionado.titulo}
                        />
                        <Galeria
                            tipoGaleria={tipoMedSeleccionado.id}
                            elementos={listadoVideos}
                        />
                    </div>
                );
            default:
                return (
                    <div className="bloque__galeria">
                        <TituloConFiltro
                            titulo='Galería no contemplada'
                        />
                    </div>
                );
        }
    }
    return (
        <>
            <Galeria
            />
        </>
    );
}
