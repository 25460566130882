import { alertaError } from "../helpers/alertas";
import { fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import { uiFinishLoading, uiStartLoading } from "./ui.actions";

export const tipoItinerarioEmpiezaListado = () => {
    const url = 'tiposItinerario';

    return async (dispatch) => {
        try {
            dispatch(uiStartLoading());

            const resp = await fetchSinToken(url);
            const body = await resp.json();

            const tiposItinerario = body.tiposItinerario;

            dispatch(tipoItinerarioListado(tiposItinerario));
            dispatch(tipoItinerarioSeleccionar(tiposItinerario[0]));

            dispatch(uiFinishLoading());
        } catch (error) {
            alertaError(error);
        };
    }
};

// Esta no se exporta
const tipoItinerarioListado = (tipoItinerario) => ({
    type: types.tipoItinerariosListado,
    payload: tipoItinerario
});

export const tipoItinerarioSeleccionar = (tipoItinerario) => ({
    type: types.tipoItinerarioSeleccionado,
    payload: tipoItinerario
});

export const itinerarioEmpiezaListado = (tipoItinerario) => {
    const url = 'itinerario';

    return async (dispatch) => {
        try {
            dispatch(uiStartLoading());

            const params = {
                key: 'tipoItinerario',
                data: tipoItinerario
            }

            const resp = await fetchSinToken(url, params);
            const body = await resp.json();

            const itinerario = body.itinerario;

            dispatch(itinerarioListado(itinerario));

            dispatch(uiFinishLoading());
        } catch (error) {
            alertaError(error);
        };
    }
};

// Esta no se exporta
const itinerarioListado = (itinerario) => ({
    type: types.itinerariosListado,
    payload: itinerario
});