import { alertaError } from '../helpers/alertas';
import { fetchSinToken, fetchSinTokenDataArray } from '../helpers/fetch';
import { types } from '../types/types';

/**
 * En este fichero vamos a registrar las acciones que se corresponden a los objetos
 * relacionados con el apartado MEDIOS, es decir:
 *  - Tipos de medio
 *  - Medios
 *  - Programas
 */

/***
 * LISTADO DE TIPOS DE MEDIOS 
 * */  
 export const tiposMedioEntrEmpiezaListado = () => {
    // console.log( 'EMPEZAMOS tiposMedioEntrEmpiezaListado');
    return async ( dispatch ) =>{
        try{
            const resp = await fetchSinToken( 'tiposMedio', 
                { 
                    key:'entrevista',
                    data:true 
                } ); // Petición GET por defecto. No se indica
            const body = await resp.json();

            const tiposMedioEntr = body.tiposMedio;

            dispatch( tiposMedioEntrListado( tiposMedioEntr ) );
            dispatch( tipoMedioEntrSeleccionado( tiposMedioEntr[0] ) );
            // console.log('tiposMedioEmpiezaListado -> id_medio: ', tiposMedio[0].id);
            // dispatch( programasEntrEmpiezaListado ( {
            //                                         'id_medio':tiposMedioEntr[0].id, 
            //                                         'entrevista': true
            //                                     } ) );

        }catch( error ){
            alertaError( 'tiposMedioEntrEmpiezaListado -> ' + error );
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const tiposMedioEntrListado = ( tiposMedioEntr ) => ({
    type: types.tiposMedioEntrListado,
    payload: tiposMedioEntr
});

export const tipoMedioEntrSeleccionado = ( tiposMedioEntr ) => ({
    type: types.tipoMedioEntrSeleccionado,
    payload: tiposMedioEntr
});

/***
 * LISTADO DE PROGRAMAS - Se listan los programas de un medio concreto.
 * */  
 export const programasEntrEmpiezaListado = ( data = {} )  => {
    // console.log('programasEntrEmpiezaListado -> data: ', data);

    const {id_medio} = data;

    return async ( dispatch ) =>{
        try{
            const resp = await fetchSinTokenDataArray( 'programa', 
                [{ key:'id_medio',
                  data:id_medio },
                { key:'entrevista',
                  data:true }]
                ); 
            const body = await resp.json();
            
            const programas = body.programas;
            
            // console.log('programasEntrEmpiezaListado -> programas: ', programas);

            dispatch( programasEntrListado( programas ) );
            dispatch( programaEntrSeleccionado( programas[0] ) ); // Seleccionamos el primer programa del array
            dispatch( episodiosEntrEmpiezaListado ( programas[0].id ) );
        }catch( error ){
            alertaError( 'programasEntrEmpiezaListado -> ' + error );
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const programasEntrListado = ( programas ) => ({
    type: types.programasEntrListado,
    payload: programas
});

export const programaEntrSeleccionado = ( programa ) => ({
    type: types.programaEntrSeleccionado,
    payload: programa
});

/***
 * LISTADO DE EPISODIOS - Se listan los episodios de un programa concreto.
 * CAMBIO 11/04/2024 - listamos según el id_medio y no por el programa
* */  
export const episodiosEntrEmpiezaListado = ({ id_medio } )  => {

    switch (id_medio) {
        case process.env.REACT_APP_MED_RADIO:
                return async ( dispatch ) =>{
                try{
                    const resp = await fetchSinTokenDataArray('episodio',
                        [
                            { key: 'activo', data: true },
                            { key: 'entrevista', data: true }
                        ]
                    );  
                    const body = await resp.json();
                    
                    const episodios = body.episodios;
        
                    dispatch(episodiosEntrListado(episodios));
        
                }catch( error ){
                    alertaError( 'episodiosEmpiezaListado RADIO -> ' + error );
                };
            }
        case process.env.REACT_APP_MED_VIDEO:
                return async ( dispatch ) =>{
                try{
                    const resp = await fetchSinTokenDataArray('video',
                        [
                            { key: 'activo', data: true },
                            { key: 'entrevista', data: true }
                        ]
                    );  
                    const body = await resp.json();
                    
                    const videos = body.videos;
        
                    dispatch( videosEntrListado( videos ) );
        
                }catch( error ){
                    alertaError( 'episodiosEmpiezaListado VIDEO -> ' + error );
                };
            }

        case process.env.REACT_APP_MED_PRENSA:
            return async ( dispatch ) =>{
                try{
                    const resp = await fetchSinTokenDataArray('prensa',
                        [
                            { key: 'activo', data: true },
                            { key: 'entrevista', data: true }
                        ] 
                    ); 
                    const body = await resp.json();
                    
                    const prensa = body.prensas;
        
                    dispatch( prensaEntrListado( prensa ) );
        
                }catch( error ){
                    alertaError( 'episodiosEmpiezaListado PRENSA -> ' + error );
                };
            }

        default:
            return {};
    }
    
};

// Esta no se exporta porque se utiliza en este fichero
const episodiosEntrListado = ( episodios ) => ({
    type: types.episodiosEntrListado,
    payload: episodios
});

export const episodioEntrSeleccionado = ( episodio ) => ({
    type: types.episodioEntreSeleccionado,
    payload: episodio
});

// Esta no se exporta porque se utiliza en este fichero
const videosEntrListado = ( videos ) => ({
    type: types.videosEntrListado,
    payload: videos
});

// // Esta no se exporta porque se utiliza en este fichero
const prensaEntrListado = ( prensa ) => ({
    type: types.prensaEntrListado,
    payload: prensa
});

// // Acción que hace que empiece el proceso de grabación
// // Si se graba correctamente en base de datos, llamo a eventoNuevo (por eso no la exporto)
// export const eventoEmpezandoNuevo = ( evento ) => {
//     return async (dispatch, getState) => {

//         const { uid, name } = getState().auth;

//         try{
//             const resp = await fetchConToken( 'eventos', evento, 'POST' );
//             const body = await resp.json();

//             if ( body.ok ){
//                 evento.id = body.evento.id;
//                 evento.user = {
//                     _id: uid,
//                     name: name
//                 }
                
//                 dispatch( eventoNuevo( evento ) );

//                 alertaOk('Evento creado correctamente');
//             } else {
//                 alertaError( body.msg );
//             }
//         } catch (error){
//             alertaError( error );
//         }
//     }
// }

// const eventoNuevo = ( evento ) => ({
//     type: types.eventoNuevo,
//     payload: evento
// });

// export const eventoSeleccionado = ( evento ) => ({
//     type: types.eventoSeleccionado,
//     payload: evento
// });

// export const eventoLimpiarSeleccionado = () => ( {
//     type: types.eventoLimpiarSeleccionado
// } );



// /***
//  * ACTUALIZACIÓN DE EVENTOS 
//  * */  
//  export const eventoEmpiezaActualizacion = ( evento ) => {
//     return async ( dispatch ) =>{
//         try{
//             console.log('eventoEmpiezaActualizacion', evento);
            
//             const resp = await fetchConToken( `eventos/${ evento.id }`, evento, 'PUT' ); // Petición PUT 
//             const body = await resp.json();

//             if ( body.ok ){

//                 dispatch( eventoActualizado( body.evento ) );

//                 alertaOk('Evento modificado correctamente');

//             } else {
//                 alertaError( body.msg );
//                 console.log('ERROR eventoEmpiezaActualizacion: ', body);
//             };
//         }catch( error ){
//             alertaError( error );
//         };
//     }
// };

// const eventoActualizado = ( evento ) => ({ 
//     type: types.eventoActualizado,
//     payload: evento 
// });

// /***
//  * BORRADO DE EVENTOS 
//  * */  
//  export const eventoEmpiezaBorrado = ( ) => {
//     return async ( dispatch, getState ) =>{

//         const { id } = getState().calendario.eventoActivo;
//         try{
//             const resp = await fetchConToken( `eventos/${ id }`, {}, 'DELETE' );
//             const body = await resp.json();

//             if ( body.ok ){

//                 dispatch( eventoBorrado( ) );
//                 alertaOk('Evento borrado correctamente');

//             } else {
//                 alertaError( body.msg );
//                 console.log('ERROR eventoEmpiezaBorrado: ', body);
//             };
//         }catch( error ){
//             alertaError( error );
//         };
//     }
// };

// const eventoBorrado = ( ) => ({
//     type: types.eventoBorrado
// });