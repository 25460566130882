import { types } from '../types/types';

const initialState = {
    premios: []
};

export const premiosReducer = ( state = initialState, action ) => {

    // console.log('premiosReducer action: ', action);
    // console.log('premiosReducer state: ', state);

    switch ( action.type) {

        case types.premiosListado:
            return{
                ...state,
                premios: action.payload
            };

        default:
            return state;
    }
};