import validator from 'validator';

export const validateForm = ( formValues ) => {

    const { userName, email, password, password2 } = formValues;
    let errors = {};

    // console.log(name, email, password, password2);
    if ( validator.isEmpty( userName ) ){
        errors.userName = 'El nombre es obligatorio';
    };

    if ( validator.isEmpty( email ) ){
        errors.email = 'El email es obligatorio';
    } else if ( !validator.isEmail( email ) ){
        errors.email = 'El email no es válido';
    };
    
    if ( validator.isEmpty( password ) ){
        errors.password = 'La password es obligatoria';
    // } 
    // else if ( !validator.isStrongPassword ( password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 } ) ){
    //     errors.password = 'La password debe tener al menos: 8 caracteres, una minúscula, una mayúscula, un número, un símbolo';
    };

    if ( validator.isEmpty( password2 ) ){
        errors.password2 = 'La password es obligatoria';
    // } 
    // else if ( !validator.isStrongPassword ( password2, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 } ) ){
    //     errors.password2 = 'La password debe tener al menos: 8 caracteres, una minúscula, una mayúscula, un número, un símbolo';
    } else if ( password !== password2){
        errors.password2 = 'Las password no coinciden';
    };

    return errors;
};