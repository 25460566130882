import { useEffect } from 'react';
import { Parrafo } from '../ui/Parrafo';
import { useDispatch, useSelector } from 'react-redux';

import { bioEmpiezaListado } from '../../actions/bio.actions';
import { dameImg } from '../../helpers/dameImg';
import { eventoGA } from '../../helpers/eventoGA';

export const BioScreen = () => {
    const dispatch = useDispatch();

    const { bio } = useSelector(state => state.bio);
    // console.log('BIO: ', bio);

    useEffect(() => {
        eventoGA('Menu_bio', 'Menu_bio');
    }, []);

    useEffect(() => {
        if (bio.length === 0) {
            dispatch(bioEmpiezaListado());
        }
    }, [bio, dispatch]);

    return (
        <>
            <div className="webAcc__content-titulo">
                <h1 className="parrafo__h1" >
                    Biografía Académica
                </h1>
                <hr />
            </div>
            <div className="webAcc__content-bloques">
                <div className="webAcc__content-contenido">
                    {/* Incluimos el HTML formateado en lugar de los párrafos traídos de la BBDD */}
                    {
                        bio.map(bio => (
                            <Parrafo
                                key={bio.id}
                                data={bio}
                            />
                        ))
                    }
                </div>
                <div className="galeria__logos">
                    <img
                        className="galeria__logos-logo"
                        src={dameImg('./logoUCM.png').default}
                        alt="logoUCM"
                    />
                    <img
                        className="galeria__logos-logo"
                        src={dameImg('./logoUAM.png').default}
                        alt="logoUAM"
                    />
                    <img
                        className="galeria__logos-logo"
                        src={dameImg('./logoCarlosIII.png').default}
                        alt="logoCarlosIII"
                    />
                    <img
                        className="galeria__logos-logo"
                        src={dameImg('./logoLudwig.png').default}
                        alt="logoLudwig"
                    />
                    <img
                        className="galeria__logos-logo"
                        src={dameImg('./logoParis.png').default}
                        alt="logoParis"
                    />
                    <img
                        className="galeria__logos-logo"
                        src={dameImg('./logoBerlin.png').default}
                        alt="logoBerlin"
                    />
                    <img
                        className="galeria__logos-logo"
                        src={dameImg('./logoUPM.png').default}
                        alt="logoUPM"
                    />
                </div>
            </div>
        </>
    );
};
