import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { uiCerrarModal } from '../../actions/ui.actions';
import { customStyles } from '../../helpers/modalCustomStyles';

export const AudioPlayer = () => {
    const dispatch = useDispatch();

    const { modalAbierta } = useSelector(state => state.ui);
    const { episodioSeleccionado } = useSelector(state => state.medios);

    const closeModal = () => {
        dispatch( uiCerrarModal() );
    };

    return (
        <Modal
          isOpen={ modalAbierta }
          onRequestClose={ closeModal }
          style={ customStyles }
          className="modal-audio-player"
          overlayClassName="modal-fondo fondo-white-transp"
          closeTimeoutMS={ 200 }
        >
            <h1> Escuchar programa</h1>
            <hr />
            <span>
                { episodioSeleccionado && episodioSeleccionado.titulo }
            </span>

            { episodioSeleccionado &&
                <audio controls autoplay="true" >
                    <source src={ episodioSeleccionado.fich } type="audio/mpeg" />
                </audio>
            }
        </Modal>
    )
}
