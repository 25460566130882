import { Redirect, Route, Switch } from 'react-router';

import { Header }               from '../components/00-estructura/Header';
import { NavBar }               from '../components/00-estructura/NavBar';
import { WebAccScreen }         from '../components/00-webAccHome/WebAccScreen';
import { BioScreen }            from '../components/01-bio/BioScreen';
import { PremiosScreen }      from '../components/02-premios/PremiosScreen';
import { PublicacionesScreen }  from '../components/03-publicaciones/PublicacionesScreen';
import { MediosScreen }         from '../components/04-medios/MediosScreen';
import { EntrevistasScreen }    from '../components/05-entrevistas/EntrevistasScreen';
import { ProximasActScreen }    from '../components/06-proximasAct/ProximasActScreen';
// import { BuscarScreen }         from '../components/buscar/BuscarScreen';
import { Footer }               from '../components/00-estructura/Footer';
import { ConferenciasScreen } from '../components/07-conferencias/ConferenciasScreen';

export const WebAccRoutes = () => {
    return (
        <div className="webAcc__container">
            <Header />
            <NavBar/>
            <main className="webAcc__main-content fondo-whitesmoke">
                <Switch>
                    <Route exact path="/" component={ WebAccScreen } />
                    <Route exact path="/bio" component={ BioScreen } />
                    <Route exact path="/premios" component={ PremiosScreen } />
                    <Route exact path="/publicaciones" component={ PublicacionesScreen } />
                    <Route exact path="/conferencias" component={ ConferenciasScreen } />
                    <Route exact path="/medios" component={ MediosScreen } />
                    <Route exact path="/entrevistas" component={ EntrevistasScreen } />
                    <Route exact path="/proximasact" component={ ProximasActScreen } />
                    {/* <Route exact path="/buscar" component={ BuscarScreen } /> */}

                    <Redirect to="/" />
                </Switch>
            </main>
            <Footer />
        </div>
    );
};