import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tiposPublicacionEmpiezaListado } from '../../actions/publicaciones.actions';
import { TipoPublicacionItem } from './TipoPublicacionItem';

export const TipoPublicacion = () => {

    const dispatch = useDispatch();

    const { tiposPublicacion } = useSelector(state => state.publicaciones);
    // console.log('useSelector: tiposPublicacion -> ', tiposPublicacion);

    useEffect(() => {
        // console.log('TipoPublicacion -> useEffect');
        dispatch(tiposPublicacionEmpiezaListado());
    }, [dispatch]);

    // console.log('tiposPublicacion -> ', tiposPublicacion);

    // console.log('tiposPublicacion length -> ', tiposPublicacion.length);
    return (
        <div className="bloque__columna">
            {
                tiposPublicacion.length > 0
                    ? tiposPublicacion.map(tipoPub => (
                        <TipoPublicacionItem
                            key={tipoPub.id}
                            data={tipoPub}
                        />
                    ))
                    : <p>Sin datos</p>
            }
        </div>
    );
}
