import { alertaError } from '../helpers/alertas';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

/***
 * LISTADO DE PREMIOS
 * */  
 export const premiosEmpiezaListado = ( data = {} )  => {

    return async ( dispatch ) =>{
        try{
            const resp = await fetchSinToken('premios'); 
            const body = await resp.json();
            const premios = body.premios;

            dispatch( premiosListado( premios ) );
            
        }catch( error ){
            alertaError( 'premiosEmpiezaListado -> ' + error );
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const premiosListado = ( premios ) => ({
    type: types.premiosListado,
    payload: premios
});

