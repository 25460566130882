// import { RichTextEditor } from '../ui/RichText/RichTextEditor';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { premiosEmpiezaListado } from '../../actions/premios.actions';
import { Galeria } from "../ui/Galeria";
import { eventoGA } from "../../helpers/eventoGA";

export const PremiosScreen = () => {

    const dispatch = useDispatch();

    const { premios } = useSelector(state => state.premios);

    useEffect(() => {
        eventoGA('Menu_premios', 'Menu_premios');
    }, []);

    useEffect(() => {
        if (premios.length === 0) {
            dispatch(premiosEmpiezaListado());
        }
    }, [premios, dispatch]);


    return (
        <div className="webAcc__content-titulo">
            <h1 className="parrafo__h1">
                Premios
            </h1>
            <hr />
            <div className="bloque__galeria_100">
                <Galeria
                    tipoGaleria='premios'
                    elementos={premios}
                />

            </div>

        </div>
    );
};