import React from 'react';
import ReactDOM from 'react-dom';

import { WebAcc } from './WebAcc';
import './styles/styles.scss';

ReactDOM.render(
  <WebAcc />,
  document.getElementById('root')
);
