import React from 'react';
import { InlineIcon } from '@iconify/react';
import iconoLink from '@iconify/icons-la/external-link-alt';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import calendarAlt from '@iconify/icons-la/calendar-alt';
import { eventoGA } from '../../helpers/eventoGA';

export const ListaItemProximaAct = (data = {}) => {
    var img = '';

    data.data.fich
        ? img = data.data.fich
        : img = process.env.REACT_APP_NO_IMAGE

    const { titulo, descripcion, fechaInicio, url, tipoActividad, lugar, online } = data.data;
    const fechaInicioISO = parseISO(fechaInicio);

    let lugarAct = '';
    (online) ? lugarAct = ', online' : lugarAct = `, en ${lugar}`;

    const estadistica = () => {
        // console.log('GaleriaItemPremios -> PULSAMOS HIPERVÍNCULO');
        eventoGA('enlace', 'actividad_' + titulo);
    };

    return (
        <div className="listaItems__item">
            <div className="listaItems__banner-fecha">
                <InlineIcon icon={calendarAlt} color="black" width="1.3em" height="1.3em" /> {(format(fechaInicioISO, "iiii", { locale: es }))}, {(format(fechaInicioISO, "d", { locale: es }))} de {(format(fechaInicioISO, "MMMM", { locale: es }))} de {(format(fechaInicioISO, "yyyy", { locale: es }))}, a partir de las {(format(fechaInicioISO, "HH:mm", { locale: es }))}
                {lugarAct}
            </div>
            {/* <hr/> */}
            <div className='listaItems__main-content'>
                <a
                    onClick={estadistica}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                >
                    <img
                        className="galeria__item-img"
                        src={img}
                        alt={titulo}
                    />
                </a>
                <div className='listaItems__item-info'>
                    <div className="listaItems__item-titulo">
                        {titulo}
                    </div>
                    <div className="listaItems__item-tipo">
                        {tipoActividad.descripcion}
                    </div>
                    <div className="listaItems__item-descripcion">
                        {descripcion}
                    </div>
                    <div className="parrafo__galeria-item-link">
                        <InlineIcon icon={iconoLink} color="black" width="1.3em" height="1.3em" />
                        <a
                            onClick={estadistica}
                            href={data.data.url}
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                        >
                            Más información...
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}
