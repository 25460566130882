import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectorBar } from "../ui/SelectorBar";
import { itinerarioEmpiezaListado, tipoItinerarioEmpiezaListado } from "../../actions/itinerario.actions";
import { Itinerarios } from "./Itinerarios";

export const TiposItinerarios = () => {

    const { tipoItinerarios, tipoItinerarioSeleccionado, itinerarios } = useSelector(state => state.publicaciones);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(tipoItinerarioEmpiezaListado());
    }, [dispatch]);

    useEffect(() => {
        if (tipoItinerarioSeleccionado) {
            dispatch(itinerarioEmpiezaListado(tipoItinerarioSeleccionado.id));
        }
    }, [dispatch, tipoItinerarioSeleccionado]);

    return (
        <div className="bloque__galeria">
            <div className="selectorBar ">
                {
                    tipoItinerarioSeleccionado && <SelectorBar
                        elementos={tipoItinerarios}
                    />
                }
            </div>
            <Itinerarios
                itinerarios={itinerarios}
            />
        </div>

    )
};