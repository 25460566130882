
export const TituloSinFiltro = ({ titulo = '' }) => {

    return (
        <>
            <div className="bloque__filtro">
                <h2 className="parrafo__h2" >
                    {titulo}
                </h2>
            </div>
            <hr />
        </>
    )
}
