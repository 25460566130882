import { ItinerarioButton } from "../03-publicaciones/ItinerarioButton";

export const SelectorBar = ({ elementos = [] }) => {

    if (elementos.length === 0) {
        return (
            <div className='parrafo'>
                No hay información en este momento para las opciones seleccionadas
            </div>
        );
    }

    return (
        <div className="selectorBar">
            {
                elementos.map(elemento => (

                    < ItinerarioButton
                        key={elemento.id}
                        data={elemento}
                    />
                ))
            }
        </div>
    )
};