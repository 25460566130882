import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';

// Functional Component que va a devolver la ruta al componente que le llama si está autenticado
// y si no devolverá un redirect a la página de login
// Este componente se implementa en las rutas que queremos proteger, en mi caso en el AppRouter

export const PrivateRoute = ({
    isAuthenticated,
    component: Component, // componente lo renombro a mayúscula
    ...rest
}) => {

    return (
        <Route {...rest}
            // Para recuperar las props de component uso el callback y así las recupero
            component={ (props) => (
                ( isAuthenticated )
                    ? <Component { ...props } />
                    // : <Redirect to="/auth/login" />
                    : <Redirect to="/auth/login" />
            )}
        />
    );
};

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
};