
export const types = {
    authLogin: '[auth] Login',
    authEmpezandoRegistro: '[auth] Empezando registro',
    authRenovandoToken: '[auth] renovando token',
    authLogout: '[auth] Logout',
    authRegistro: '[auth] Logout',

    // bio
    bioEmpiezaListado: '[bio] Empezar listado bio',
    bioListado: '[bio] Listado bio',

    // premios
    premiosEmpiezaListado: '[premio] Empezar listado premios',
    premiosListado: '[premio] Listado premios',

    // medios
    // Tipos de MEDIO (ej, radio, prensa, TV...)
    tiposMedioEmpiezaListado: '[tipoMedio] Empezar listado tiposMedio',
    tiposMedioListado: '[tipoMedio] Listado tiposMedio',
    tipoMedioSeleccionado: '[tipoMedio] Seleccionado',
    // Programas (ej, Hora 25, TV3...)
    programasEmpiezaListado: '[programa] Empezar listado programa',
    programasListado: '[programa] Listado programa',
    programaSeleccionado: '[programa] Seleccionado',
    // Episodios (cada programa)
    episodiosEmpiezaListado: '[episodio] Empezar listado episodio',
    episodiosListado: '[episodio] Listado episodio',
    episodioSeleccionado: '[episodio] Seleccionado',
    videosListado: '[video] Listado videos',
    prensaListado: '[prensa] Listado prensa',

    // conferencias
    conferenciasListado: '[conferencias] Listado conferencias',

    // entrevistas
    // Tipos de MEDIO (ej, radio, prensa, TV...) que tienen entrevistas asociadas
    tiposMedioEntrEmpiezaListado: '[tipoMedioEntr] Empezar listado tiposMedio para entrevistas',
    tiposMedioEntrListado: '[tipoMedioEntr] Listado tiposMedio para entrevistas',
    tipoMedioEntrSeleccionado: '[tipoMedioEntr] Seleccionado para entrevistas',
    // Programas (ej, Hora 25, TV3...)
    programasEntrEmpiezaListado: '[programaEntr] Empezar listado programa para entrevistas',
    programasEntrListado: '[programaEntr] Listado programa para entrevistas',
    programaEntrSeleccionado: '[programaEntr] Seleccionado para entrevistas',
    programaEntrLimpiarSeleccionado: '[programaEntr] Limpiar seleccionado para entrevistas',
    // Episodios (cada programa)
    episodiosEntrEmpiezaListado: '[episodioEntr] Empezar listado episodio para entrevistas',
    episodiosEntrListado: '[episodioEntr] Listado episodio para entrevistas',
    episodioEntrSeleccionado: '[episodioEntr] Seleccionado para entrevistas',
    videosEntrListado: '[videoEntr] Listado videos para entrevistas',
    prensaEntrListado: '[prensaEntr] Listado prensa para entrevistas',

    // Tipos de PUBLICACION (ej, libro, artículo...)
    tiposPublicacionEmpiezaListado: '[tipoPublicacion] Empezar listado tiposPublicacion',
    tiposPublicacionListado: '[tipoPublicacion] Listado tiposPublicacion',
    tipoPublicacionSeleccionado: '[tipoPublicacion] Seleccionado',
    // Libros
    librosEmpiezaListado: '[libro] Empezar listado publicaciones',
    librosListado: '[libro] Listado publicaciones',
    revistasListado: '[revista] Listado publicaciones',
    articulosListado: '[articulo] Listado publicaciones',

    // Tipos de ACTIVIDAD (ej, ciclo, conferencia...)
    tiposActividadEmpiezaListado: '[tipoActividad] Empezar listado tiposActividad',
    tipoActividadListado: '[tipoActividad] Listado tiposActividad',
    tipoActividadSeleccionado: '[tipoActividad] Seleccionado',

    // Actividades
    actividadesListado: '[actividad] Listado actividades',
    siguienteActiv: '[actividad] Siguiente actividad',
    // proximasactEmpiezaListado: '[actividad] Listado proximas actividades', 

    // itinerarios
    itinerariosListado: '[itinerarios] Listado itinerarios',
    itinerarioSeleccionado: '[itinerarios] Seleccionado',
    tipoItinerariosListado: '[tipoItinerario] Listado tipos de itinerarios',
    tipoItinerarioSeleccionado: '[tipoItinerario] Seleccionado',

    // Acciones para el loading
    uiStartLoading: '[ui] Empezar carga',
    uiFinishLoading: '[ui] Terminar carga',

    // Variable para controlar la ventana modal
    uiModalAbierta: '[ui] Modal abierta',
    uiModalCerrada: '[ui] Modal cerrada',

    // Variables para las acciones de la tabla
    uiFilaParaBorrar: '[ui] Fila para borrar',

    // Variable para la búsqueda de texto
    uiBuscaTexto: '[ui] Incluido texto a buscar',
    uiOpcionTituloFiltro: '[ui] Cambiado estado opción tituloFiltro',

    uiIpUser: '[ui] Ip user',

    // Vista de calendario para cuando se recargue la pantalla
    calVistaCalDia: '[cal] Vista calendario dia',
    calVistaCalSemana: '[cal] Vista calendario semana',
    calVistaCalMes: '[cal] Vista calendario mes',

    // Tipos de evento
    tipoEventoEmpiezaListado: '[tipoEvento] Empezar listado tipos evento',
    tipoEventoListado: '[tipoEvento] Listado tipos evento',

    // EVENTOS
    eventoEmpezandoNuevo: '[evento] Empezar nuevo evento',
    eventoNuevo: '[evento] Nuevo evento',
    eventoActualizado: '[evento] Actualizado',
    eventoBorrado: '[evento] Borrado',
    eventoSeleccionado: '[evento] Seleccionado',
    eventoLimpiarSeleccionado: '[evento] Limpiar seleccionado',
    eventoEmpiezaListado: '[evento] Empezar listado eventos',
    eventoListado: '[evento] Listado eventos',

}