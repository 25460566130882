import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { publicacionesEmpiezaListado, tipoPublicacionSeleccionado } from '../../actions/publicaciones.actions';
import { tipoPublicacionSeleccionado } from '../../actions/publicaciones.actions';
// import { itinerarioEmpiezaListado } from '../../actions/itinerario.actions';
import { dameImg } from '../../helpers/dameImg';

export const TipoPublicacionItem = (data = {}) => {

    var estiloBoton = '';

    const { imgTipoPublicacion, descripcion } = data.data;

    const { tipoPublicSeleccionado } = useSelector(state => state.publicaciones);

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(tipoPublicacionSeleccionado(data.data));
        // AQUÍ YA NO LLAMAMOS porque hemos puesto un useEffect en Publicaciones.js
        // (!data.data.itinerario)
        //     ? dispatch(publicacionesEmpiezaListado(data.data))
        //     : dispatch(itinerarioEmpiezaListado(data.data.id))
    };

    tipoPublicSeleccionado &&
        tipoPublicSeleccionado.id === data.data.id
        ? estiloBoton = "opcion__boton-item-seleccionado shadow"
        : estiloBoton = "opcion__boton-item shadow";

    return (
        <button
            className={estiloBoton}
            onClick={handleClick}
        >
            <div className="opcion__boton-item-img">
                <img src={dameImg(`./${imgTipoPublicacion}.png`).default} alt={imgTipoPublicacion} />
            </div>
            <div className="opcion__boton-item-name">
                {descripcion}
            </div>
        </button>
    );
}
