import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import doorOpen from '@iconify/icons-la/door-open';
import checkSquareSolid from '@iconify/icons-la/check-square-solid';

import { startLogout } from '../../actions/auth.actions';

export const PrivNavBar = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { name } = useSelector( state => state.auth ); // Recupero el ui del state almacenado en el Store

    const  handleLogout = () => {
    //    alertaConfirmacion( '¿Seguro que quieres desconectarte?', startLogout() );
    //TODO: el diálogo de confirmación no funciona
        dispatch( startLogout() );
    };

    const handleNavegarPublico = () =>{
        const path = history.location.pathname.split('/');
        history.replace('/' + path[2]);
    };

    return (
        <nav className="navbar fondo-primary">
            <div className="navbar__group">
                <NavLink
                    activeClassName="navbar__private-item-current"
                    className="navbar__item" 
                    exact
                    to="/app/bio"
                >
                    Bio Académica
                </NavLink>

                <NavLink
                    activeClassName="navbar__private-item-current"
                    className="navbar__item" 
                    exact
                    to="/app/incordiar"
                >
                    El arte de incordiar
                </NavLink>

                <NavLink
                    activeClassName="navbar__private-item-current"
                    className="navbar__item" 
                    exact
                    to="/app/publicaciones"
                >
                    Publicaciones
                </NavLink>

                <NavLink 
                    activeClassName="navbar__private-item-current"
                    className="navbar__item" 
                    exact
                    to="/app/medios"
                >
                    Medios
                </NavLink>

                <NavLink 
                    activeClassName="navbar__private-item-current"
                    className="navbar__item" 
                    exact
                    to="/app/entrevistas"
                >
                    Entrevistas
                </NavLink>

                <NavLink 
                    activeClassName="navbar__private-item-current"
                    className="navbar__item" 
                    exact
                    to="/app/actividades"
                >
                    Actividades
                </NavLink>

                <NavLink 
                    activeClassName="navbar__private-item-current"
                    className="navbar__item" 
                    exact
                    to="/app/eventos"
                >
                    Próximos eventos
                </NavLink>
            </div>

            <div className="navbar__group">
                <span className="navbar__private-nombre">
                    { name }
                </span>

                <button 
                    className="boton-plano-primary"
                    onClick={ handleNavegarPublico }
                >
                    <Icon icon={ checkSquareSolid } color="white" width="2em" height="2em" />
                </button>

                <button 
                    className="boton-plano-primary"
                    onClick={ handleLogout }
                >
                    <Icon icon={ doorOpen } color="white" width="2em" height="2em" />
                    Salir
                </button>
            </div>
        </nav>
    );
}
