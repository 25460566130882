
export const grabaLocalStorage = ( key, valor ) => {
    return localStorage.setItem( key, valor );
}

export const dameLocalStorage = ( key ) => {
    return localStorage.getItem( key );
}

export const limpiaLocalStorage = () => {
    return localStorage.clear();
}