import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { programasEntrEmpiezaListado, tipoMedioEntrSeleccionado } from '../../actions/entrevistas.actions';
import { dameImg } from '../../helpers/dameImg';

export const TipoMedioEntrItem = (data = {}) => {
    var estiloBoton = '';

    const { imgTipoMedio, descripcion, id } = data.data;

    const dispatch = useDispatch();

    const { tipoMedEntrSeleccionado } = useSelector(state => state.entrevistas);

    const handleClick = () => {
        dispatch(tipoMedioEntrSeleccionado(data.data));
    };

    tipoMedEntrSeleccionado &&
        tipoMedEntrSeleccionado.id === id
        ? estiloBoton = "opcion__boton-item-seleccionado shadow"
        : estiloBoton = "opcion__boton-item shadow";

    return (
        <button
            className={estiloBoton}
            onClick={handleClick}
        >
            <div className="opcion__boton-item-img">
                <img src={dameImg(`./${imgTipoMedio}.png`).default} alt={imgTipoMedio} />
            </div>
            <div className="opcion__boton-item-name">
                {descripcion}
            </div>
        </button>
    );
};
