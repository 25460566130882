import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from "react-ga4"; // Google Analytics

import { AuthRouter } from './AuthRouter';
import { PrivateAppRouter } from './PrivateAppRouter';
import { WebAccRoutes } from './WebAccRouter';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { empezarValidacionToken } from '../actions/auth.actions';
// import axios from 'axios';

// Configuración de Google Analytics
export const iniciaGA = (id) => {
    // if (process.env.NODE_ENV === 'production'){
    ReactGA.initialize(id);
    // }
};

// const getAxiosData = async () => {
//     console.log('AppRouter -> process.env.REACT_APP_API_URL: ', process.env.REACT_APP_API_URL);
//     const res = await axios.get('https://api.ipify.org/?format=json');
//     console.log('AppRouter -> ip: ', res.data.ip);
//     return res;
// };

export const AppRouter = () => {

    const textoParaIndex = false; // Texto que pongo en el cuerpo de la web para que salga en google, pero NO mostramos.

    const dispatch = useDispatch();

    // Este estado me permite esperar a que se verifique si el usuario está logado o no
    const [logging, setLogging] = useState(true);

    const { isLogged } = useSelector(state => state.auth);

    useEffect(() => {
        if (isLogged) {
            // Se verifica si el token del usuario logado es válido
            setLogging(true);
            dispatch(empezarValidacionToken());
            setLogging(false);
        } else {
            setLogging(false);
        }
    }, [dispatch, setLogging, isLogged]);

    //Recuperar la IP del usuario
    // useEffect(() => {
    //     const data = getAxiosData();
    //     console.log('AppRouter -> data.data: ', data.data);
    // }, []);


    if (logging) {
        return (
            <div>
                <h1>Entrando...</h1>
            </div>
        )
    };


    return (
        <Router>
            <>
                <Switch>
                    <PrivateRoute
                        path="/app"
                        component={PrivateAppRouter}
                        isAuthenticated={isLogged}
                    />
                    <PublicRoute
                        exact
                        path="/auth/login"
                        component={AuthRouter}
                        isAuthenticated={isLogged}
                    />
                    <Route
                        path="/"
                        component={WebAccRoutes}
                        isAuthenticated={isLogged}
                    />
                    <Redirect to="/app" />
                </Switch>
            </>
            {textoParaIndex && <>
                Información sobre la biografía académica y el trabajo de la filósofa española Ana Carrasco Conde
            </>}

        </Router>
    );
};