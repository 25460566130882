import React, { useEffect } from 'react';
import { ProximasAct } from './ProximasAct';
import { eventoGA } from '../../helpers/eventoGA';

export const ProximasActScreen = () => {
    useEffect(() => {
        eventoGA('Menu_actividades', 'Menu_actividades');
    }, []);

    return (
        <>
            <div className="webAcc__content-titulo">
                <h1 className="parrafo__h1" >
                    Próximas Actividades
                </h1>
                <hr />
            </div>
            <div className="bloque__galeria_100">
                <ProximasAct />
            </div>
        </>

    );
};