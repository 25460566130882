

export const formatearItinerarios = ({ itinerarios }) => {

    if (!itinerarios || itinerarios.length < 1)
        return {};

    const libros = itinerarios.filter(itinerario => itinerario.tipoPublicacion.id === process.env.REACT_APP_PUBL_LIBROS);

    const articulos = itinerarios.filter(itinerario =>
        itinerario.tipoPublicacion.id === process.env.REACT_APP_PUBL_ARTICULOS
    );
    const capitulos = itinerarios.filter(itinerario =>
        itinerario.tipoPublicacion.id === process.env.REACT_APP_PUBL_CAPITULOS
    );

    const librosGaleria = libros.map(elemento => (elemento.libro));
    const articulosGaleria = articulos.map(elemento => (elemento.articulo));
    const capitulosGaleria = capitulos.map(elemento => (elemento.articulo));

    return (
        {
            librosGaleria,
            articulosGaleria,
            capitulosGaleria
        }
    )
};