import { useCallback, useMemo } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

const Element = ({ attributes, children, element }) => {

    switch (element.type) {
        case 'bulletList':
            return <ul {...attributes}>{children}</ul>
        case 'numberedList':
            return <ol {...attributes}>{children}</ol>
        case "heading":
            return <h3 {...attributes}>{children}</h3>;
        default:
            return <p {...attributes}>{children}</p>
    }
};

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
};

export const RichTextVisor = ({ value }) => {

    // console.log('RichTextVisor -> value: ', value);
    
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(() => withReact(createEditor()), []);

    if ( !value ) return 'No hay nada que mostrar';
    
    return (
        <Slate editor={ editor } initialValue={ value }>
            <Editable
                readOnly
                renderElement={ renderElement }
                renderLeaf={ renderLeaf }            
            />
        </Slate>                    
    );
};