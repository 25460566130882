import React from 'react';
import { InlineIcon } from '@iconify/react';
import iconoLink from '@iconify/icons-la/external-link-alt';
import { format, parseISO, isAfter, subDays } from 'date-fns';
import { es } from 'date-fns/locale';

import { dameImg } from '../../helpers/dameImg';
import { eventoGA } from '../../helpers/eventoGA';

export const GaleriaItemPremios = (data = {}) => {

    const { nombre, tipoPremio, fechaPremio, url } = data.data;

    const hace30dias = subDays(new Date(), 30);
    const fechaISO = parseISO(fechaPremio);

    var img = '';

    data.data.fich
        ? img = data.data.fich
        : img = process.env.REACT_APP_NO_IMAGE

    const estadistica = () => {
        // console.log('GaleriaItemPremios -> PULSAMOS HIPERVÍNCULO');
        eventoGA('enlace', 'premio_' + nombre);
    };

    return (
        <div className='galeria__item'>
            {isAfter(parseISO(data.data.actualizado), hace30dias) &&
                <div className='galeria__item-img-nuevo animated flash'>
                    <img
                        src={dameImg('./nuevo.png').default}
                        alt='Nuevo'
                    />
                </div>
            }
            <a
                onClick={estadistica}
                href={url}
                target="_blank"
                rel="noreferrer"
                className="link"
            >
                <img
                    className='galeria__item-img'
                    src={img}
                    alt={nombre}
                />
            </a>
            <div className="parrafo__galeria-item-titulo">
                <span>{nombre}</span>
            </div>
            <div className="parrafo__galeria-item-info">
                <span>{tipoPremio} ({(format(fechaISO, "yyyy", { locale: es }))})</span>
            </div>
            <div className="parrafo__galeria-item-link">
                <InlineIcon icon={iconoLink} color="black" width="1.3em" height="1.3em" />
                <a
                    onClick={estadistica}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                >
                    Más información...
                </a>
            </div>
        </div>
    )
}
