import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Galeria } from '../ui/Galeria';
import { TituloConFiltro } from '../ui/TituloConFiltro';
import { conferenciasEmpiezaListado } from '../../actions/conferencias.actions';

export const Conferencias = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(conferenciasEmpiezaListado());
    }, [dispatch]);

    const { videos } = useSelector(state => state.medios);
    const { textoBusqueda } = useSelector(state => state.ui);

    const [listadoVideos, setListadoVideos] = useState(videos);

    useEffect(() => {
        if (videos && textoBusqueda.length > 2) {
            const listado1 = videos.filter(video => (video.titulo.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado2 = videos.filter(video => (video.programa.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));
            const listado3 = videos.filter(video => (video.organizador.nombre.toUpperCase().includes(textoBusqueda.toUpperCase()) === true));

            setListadoVideos(listado1.concat(listado2, listado3));
        } else {
            setListadoVideos(videos);
        }
    }, [textoBusqueda, videos]);

    if (listadoVideos)
        return (
            <div className="bloque__galeria_100">
                <TituloConFiltro
                    titulo={''}
                />
                <Galeria
                    tipoGaleria={process.env.REACT_APP_MED_VIDEO}
                    elementos={listadoVideos}
                />
            </div>
        );
    else
        return (
            <>
                <Galeria
                />
            </>
        );
}