import { useDispatch, useSelector } from "react-redux";

import { tipoItinerarioSeleccionar } from '../../actions/itinerario.actions';
import { eventoGA } from "../../helpers/eventoGA";

export const ItinerarioButton = ({ data: elemento }) => {

    const dispatch = useDispatch();

    var estiloBoton = '';

    const onClickAction = () => {
        dispatch(tipoItinerarioSeleccionar(elemento));
        eventoGA('Itinerario', 'itinerario_' + elemento.nombre);
    };

    const { tipoItinerarioSeleccionado } = useSelector(state => state.publicaciones);

    tipoItinerarioSeleccionado &&
        tipoItinerarioSeleccionado.id === elemento.id
        ? estiloBoton = "selectorBarButton-seleccionado shadow"
        : estiloBoton = "selectorBarButton shadow";

    return (
        <button
            // className="boton boton-primary" // fab = Floating Bottom Action, definido en styles también
            className={estiloBoton} // fab = Floating Bottom Action, definido en styles también
            onClick={onClickAction}
        >
            <span className="selectorBarButton-text">
                {elemento.nombre}
            </span>
        </button>
    )
};