import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactSwitch from 'react-switch';
import { uiOpcionTituloFiltro } from '../../actions/ui.actions';
import { CampoBusqueda } from './CampoBusqueda';

export const TituloConFiltro = ({ titulo = '', opcionLabel = '' }) => {
    // Si recibimos el array de botones de opción, lo pintamos
    // El objeto que nos llegue debe ser así:
    // titulo : 'Titulo del botón'
    // const [botonesOpcion, setBotonesOpcion] = useState(opciones);

    // console.log('TITULO de TituloConFiltro: ', titulo);
    // console.log('OPCION LABEL de TituloConFiltro: ', opcionLabel);

    const dispatch = useDispatch();

    const [seleccionado, setSeleccionado] = useState(true);

    const handleChange = valorSeleccion => {
        dispatch(uiOpcionTituloFiltro(!seleccionado));
        setSeleccionado(!seleccionado);
    };

    return (
        <div>
            <div className="bloque__filtro">
                <h2 className="parrafo__h2" >
                    {titulo}
                </h2>
                <div className="bloque__filtro-campos">
                    <div className="parrafo__galeria-item-info">
                        {opcionLabel}
                    </div>
                    {
                        opcionLabel && <ReactSwitch
                            onChange={handleChange}
                            checked={seleccionado}
                            className="interruptor"
                        />
                    }
                    <CampoBusqueda
                        mostrarLabel={false}
                    />
                </div>
            </div>
            <hr />
        </div>
    )
}
