import { types } from '../types/types';

const initialState = {
    articulos: [],
    libros: [],
    revistas: [],
    itinerarios: [],
    tiposPublicacion: [],
    tipoPublicSeleccionado: null,
    itinerarioSeleccionado: null
};

export const publicacionesReducer = (state = initialState, action) => {

    // console.log('publicacionesReducer action: ', action);
    // console.log('publicacionesReducer state: ', state);

    switch (action.type) {

        case types.tiposPublicacionListado:
            return {
                ...state,
                tiposPublicacion: [...action.payload]
            };

        case types.tipoPublicacionSeleccionado:
            return {
                ...state,
                tipoPublicSeleccionado: action.payload
            };

        case types.librosListado:
            return {
                ...state,
                libros: [...action.payload]
            };

        case types.revistasListado:
            return {
                ...state,
                revistas: [...action.payload]
            };

        case types.articulosListado:
            return {
                ...state,
                articulos: [...action.payload]
            };

        case types.tipoItinerariosListado:
            return {
                ...state,
                tipoItinerarios: [...action.payload]
            };

        case types.tipoItinerarioSeleccionado:
            return {
                ...state,
                tipoItinerarioSeleccionado: action.payload
            };

        case types.itinerariosListado:
            return {
                ...state,
                itinerarios: [...action.payload]
            };

        default:
            return state;
    }
};