import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uiBuscaTexto } from '../../actions/ui.actions';

export const CampoBusqueda = ( data = {} ) => {

    const dispatch = useDispatch();

    const [textoFiltro, setTextoFiltro] = useState('');

    const handleOnChange = ( e ) => {
        e.persist();
        setTextoFiltro( e.target.value );
        dispatch( uiBuscaTexto( e.target.value ) );
    }

    return (
        <div className="input__group">
            {
                data.mostrarLabel && <label className="label" >Búsqueda:</label>
            }
            <input 
                name="filtro"
                className="input-search shadow"
                value = { textoFiltro }
                autoComplete="off"
                placeholder="Texto a buscar"
                onChange = { handleOnChange }
            />
        </div>
    )
}
