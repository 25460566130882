import { types } from '../types/types';

const InitialState = {
    loading: false,
    modalAbierta: false,
    textoBusqueda: '',
    opcionTituloFiltro: true,
    filaABorrar: ''
}

export const uiReducer = (state = InitialState, action) => {

    // console.log('uiReducer action: ', action);
    // console.log('uiReducer state: ', state);

    switch (action.type) {
        case types.uiStartLoading:
            // console.log( 'START LOADING' );
            return {
                ...state,
                loading: true
            };

        case types.uiFinishLoading:
            // console.log( 'FINISH LOADING' );
            return {
                ...state,
                loading: false
            };

        case types.uiModalAbierta:
            // console.log( 'MODAL ABIERTA' );
            return {
                ...state,
                modalAbierta: true
            };

        case types.uiModalCerrada:
            // console.log( 'MODAL CERRADA' );
            return {
                ...state,
                modalAbierta: false
            };

        case types.uiBuscaTexto:
            return {
                ...state,
                textoBusqueda: action.payload
            };

        case types.uiOpcionTituloFiltro:
            return {
                ...state,
                opcionTituloFiltro: action.payload
            };

        case types.uiFilaParaBorrar:
            return {
                ...state,
                filaABorrar: action.payload
            };

        case types.uiIpUser:
            return {
                ...state,
                uiIpUser: action.payload
            };

        default:
            return state;
    };
};