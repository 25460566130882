import { alertaError, alertaOk } from '../helpers/alertas';
import { fetchConToken, fetchSinToken } from '../helpers/fetch';
import { grabaLocalStorage, limpiaLocalStorage } from '../helpers/persistirLocalStorage';
import { types } from '../types/types';
import { uiFinishLoading, uiStartLoading } from './ui.actions';


export const startLoginEmailPassword=( email, password ) =>{
    // Esta función va a devolver un callback dentro del cual se realiza un dispatch
    // Además, en este caso es una función asíncrona (consulta a BBDD)

    return async ( dispatch ) =>{ 
        
        dispatch( uiStartLoading());
        // Usamos el helper que nos hemos creado para lanzar las peticiones sin token
        const resp = await fetchSinToken( 'auth/login', { email, password }, 'POST' );
        const body = await resp.json();
        /**
         *  Cuando el servicio es ok, me devuelve los datos, y cuando es ko, los errores.
         *  Por eso no puedo desestructurar el body.
         *  El parámetro común es el ok
         */

        if( body.ok ) {
            grabaLocalStorage( 'token', body.token );
            grabaLocalStorage( 'token-init', new Date().getTime() ); // Guardo cuándo se ha generado el token
            dispatch( authLogin( body.usuario.uid , body.usuario.userName ) );
        } else {
            alertaError( body.msg );
        };

        dispatch( uiFinishLoading());
    };
};

export const authLogin = ( uid, displayName ) => {
    return{
        type: types.authLogin,
        payload:
        {
            uid,
            displayName
        }
    };
};

export const empezarRegistro=( email, password, userName ) =>{
    // Esta función va a devolver un callback dentro del cual se realiza un dispatch
    // console.log( email, password, userName );
    return async ( dispatch ) =>{
        dispatch( uiStartLoading());

        const role = 'USER_ROLE';

        const resp = await fetchSinToken( 'usuarios', { email, password, userName, role }, 'POST' );
        const body = await resp.json();

        if( body.ok ) {
            // grabaLocalStorage( 'token', body.token );
            // grabaLocalStorage( 'token-init', new Date().getTime() ); // Guardo cuándo se ha generado el token
            dispatch( authLogin( body.usuario.uid , body.usuario.userName ) );
            alertaOk ( 'El alta se ha realizado correctamente' );
        } else {
            alertaError( body.msg );

        };

        dispatch( uiFinishLoading());
    };
};

export const empezarValidacionToken = () => {
    
    return async ( dispatch ) =>{
        dispatch( uiStartLoading());

        const resp = await fetchConToken( 'auth' ); // No indico método, así que por defecto es GET
        const body = await resp.json();

        if( body.ok ) {
            grabaLocalStorage( 'token', body.token );
            grabaLocalStorage( 'token-init', new Date().getTime() ); // Guardo cuándo se ha generado el token
            dispatch( authLogin( body.usuarioAut.uid , body.usuarioAut.userName ) );
        } else {
            console.log( 'Error validando token', body.msg );
        };
        dispatch( validacionTokenterminada() );
        dispatch( uiFinishLoading());
    };
};

const validacionTokenterminada = () => ({ type: types.authRenovandoToken });

export const startLogout = () =>{
    return  ( dispatch ) =>{
        limpiaLocalStorage();
        dispatch( authLogout() );
    };
};

export const authLogout = () => ({ type: types.authLogout });