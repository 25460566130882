import React, { useEffect } from 'react';
import { Entrevistas } from './Entrevistas';
import { TipoMedioEntr } from './TipoMedioEntr';
import { eventoGA } from '../../helpers/eventoGA';

export const EntrevistasScreen = () => {
    useEffect(() => {
        eventoGA('Menu_entrevistas', 'Menu_entrevistas');
    }, []);

    return (
        <>
            <div className="webAcc__content-titulo">
                <h1 className="parrafo__h1" >
                    Entrevistas
                </h1>
                <hr />
            </div>
            <div className="webAcc__content-bloques">
                <TipoMedioEntr />
                <Entrevistas />
            </div>
        </>
    );
};