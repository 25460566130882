import { combineReducers } from 'redux';

import { actividadesReducer } from './actividadesReducer';
import { authReducer } from './authReducer';
import { bioReducer } from './bioReducer';
import { calendarioReducer } from './calendarioReducer';
import { entrevistasReducer } from './entrevistasReducer';
import { mediosReducer } from './mediosReducer';
import { premiosReducer } from './premiosReducer';

import { publicacionesReducer } from './publicacionesReducer';
import { uiReducer } from './uiReducer';

export const rootReducer = combineReducers({
    actividades: actividadesReducer,
    auth: authReducer,
    bio: bioReducer,
    calendario: calendarioReducer,
    entrevistas: entrevistasReducer,
    medios: mediosReducer,
    publicaciones: publicacionesReducer,
    ui: uiReducer,
    premios: premiosReducer,
});