import { alertaError } from '../helpers/alertas';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';

/**
 * En este ficharo vamos a registrar las acciones que se corresponden a los objetos
 * relacionados con el apartado ACTIVIDADES, es decir:
 *  - Tipos de actividades
 *  - Actividades
 */

/***
 * LISTADO DE TIPOS DE ACTIVIDAD 
 * */  
 export const tiposActividadEmpiezaListado = () => {
    return async ( dispatch ) =>{
        try{
            const resp = await fetchSinToken( 'tiposActividad' ); // Petición GET por defecto. No se indica
            const body = await resp.json();

            const tiposActividad = body.tiposActividad;

            // console.log( 'tiposActividadEmpiezaListado -> ',  tiposActividad);

            dispatch( tiposActividadListado( tiposActividad ) );
            dispatch( tipoActividadSeleccionado( tiposActividad[0] ) );
            dispatch( siguienteActivListado( ) ); // Recuperamos la siguiente actividad, independientemente del tipo de actividad
            dispatch( actividadesEmpiezaListado( tiposActividad[0].id ) )

        }catch( error ){
            alertaError( 'tiposActividadEmpiezaListado ' + error );
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const tiposActividadListado = ( tiposActividad ) => ({
    type: types.tiposActividadListado,
    payload: tiposActividad
});

export const tipoActividadSeleccionado = ( tiposActividad ) => ({
    type: types.tipoActividadSeleccionado,
    payload: tiposActividad
});

/***
 * LISTADO DE ACTIVIDADES
 * */  
 export const actividadesEmpiezaListado = ( data = {} )  => {

    return async ( dispatch ) =>{
        try{
            // Primero recupero la siguiente actividad
            const respUlt = await fetchSinToken( 'actividad', 
                { key:'siguienteActividad',
                  data:true }
                ); 
            const bodyUlt = await respUlt.json();
            const actividad = bodyUlt.actividades[0];

            // Ahora recupero las actividades
            const resp = await fetchSinToken( 'actividad', 
            { key:'tipoActividad',
            data:data }
            ); 
            const body = await resp.json();
            const actividades = body.actividades;

            // Ahora quito la siguiente actividad del array de actividades
            if (actividad){
                const listado = actividades.filter( activ => ( activ.id !== actividad.id ));
                dispatch( actividadesListado( listado ) );
            } else {
                dispatch( actividadesListado( actividades ) );
            }
            
        }catch( error ){
            alertaError( 'actividadesEmpiezaListado -> ' + error );
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const actividadesListado = ( actividades ) => ({
    type: types.actividadesListado,
    payload: actividades
});

/***
 * SIGUIENTE ACTIVIDAD
 * */  
 export const siguienteActivListado = ( )  => {
     
    return async ( dispatch ) =>{
        try{
            const resp = await fetchSinToken( 'actividad', 
                { key:'siguienteActividad',
                  data:true }
                ); 
            const body = await resp.json();
            
            const actividad = body.actividades[0];

            // console.log('Siguiente actividad: ', actividad);

            dispatch( siguienteActiv( actividad ) );
            // dispatch( programaSeleccionado( programas[0] ) ); // Seleccionamos el primer programa del array
            // dispatch( episodiosEmpiezaListado ( programas[0].id ) );
        }catch( error ){
            alertaError( 'siguienteActiv -> ' + error );
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const siguienteActiv = ( actividad ) => ({
    type: types.siguienteActiv,
    payload: actividad
});

/***
 * LISTADO DE ACTIVIDADES - trae todas las actividades a futuro
 * */  
 export const proximasactEmpiezaListado = ( data = {} )  => {

    return async ( dispatch ) =>{
        try{
            const respUlt = await fetchSinToken( 'actividad', 
                { key:'siguienteActividad',
                  data:true }
                ); 
            const body = await respUlt.json();
            const actividades = body.actividades;

            dispatch( actividadesListado( actividades ) );
            
        }catch( error ){
            alertaError( 'actividadesEmpiezaListado -> ' + error );
        };
    }
};
