import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { startLoginEmailPassword } from '../../actions/auth.actions';
import { validateForm } from '../../validation/loginScreen';
import { useForm } from '../../hooks/useForm';

export const LoginScreen = () => {

    const dispatch = useDispatch();
    const { loading } = useSelector( state => state.ui ); // Recupero el ui del state almacenado en el Store

    const doLogin = ( ) => {
        try{
            dispatch( startLoginEmailPassword ( email, password ) ); // Lanzamos la acción que hace el login
        } catch (error){
            
        }
    };

    const [ formValues, handleInputChange, handleSubmit, errors ] = useForm({
            email: 'paty.carrion@gmail.com',
            password: '10Junio08'
        },
        doLogin,
        validateForm
    );

    const { email, password } = formValues;

    return (
        <>
            <h3 className="form__title mb-5">Login</h3>
            <form onSubmit={ handleSubmit }>
                <input 
                    className={`form__input ${ errors.email && 'form__input-error' }` }
                    type="text"
                    placeholder="email"
                    name="email"
                    autoComplete="off"
                    value={ email }
                    onChange={ handleInputChange }
                />
                {errors.email && (
                    <p className="form__error">{errors.email}</p>
                )}
                <input
                    className={`form__input ${ errors.password && 'form__input-error' }` }
                    type="password"
                    placeholder="password"
                    name="password"
                    value={ password }
                    onChange={ handleInputChange }
                />
                {errors.password && (
                    <p className="form__error">{errors.password}</p>
                )}
                <button
                    className="boton boton-primary boton-block"
                    type="submit"
                    disabled={ loading }
                >
                    Login
                </button>

                <Link 
                    to="/auth/register"
                    className='link'
                >
                    Crear nueva cuenta
                </Link>
            </form>

        </>
    );
};
