import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bookIcon from '@iconify/icons-la/book';
import awardIcon from '@iconify/icons-la/award';
import userGraduate from '@iconify/icons-la/user-graduate';
import calendarAltSolid from '@iconify/icons-la/calendar-alt-solid';
import broadcastTower from '@iconify/icons-la/broadcast-tower';
import microphoneAlt from '@iconify/icons-la/microphone-alt';
import lectern from '@iconify/icons-mdi/lectern';

export const NavBar = () => {
    const sizeIconMovil = '3em';

    return (
        <nav className="navbar fondo-grey-dark">
            <div className="navbar__group">
                <NavLink
                    activeClassName="navbar__item-current"
                    className="navbar__item"
                    exact
                    to="/bio"
                >
                    Bio Académica
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-current"
                    className="navbar__item"
                    exact
                    to="/premios"
                >
                    Premios
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-current"
                    className="navbar__item"
                    exact
                    to="/publicaciones"
                >
                    Publicaciones
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-current"
                    className="navbar__item"
                    exact
                    to="/conferencias"
                >
                    Conferencias
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-current"
                    className="navbar__item"
                    exact
                    to="/medios"
                >
                    Medios
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-current"
                    className="navbar__item"
                    exact
                    to="/entrevistas"
                >
                    Entrevistas
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-current"
                    className="navbar__item"
                    exact
                    to="/proximasact"
                >
                    Próximas actividades
                </NavLink>

            </div>
            <div className="navbar__group-movil">
                <NavLink
                    activeClassName="navbar__item-movil-current"
                    className="navbar__item-movil"
                    exact
                    to="/bio"
                >
                    <Icon icon={userGraduate} width={sizeIconMovil} height={sizeIconMovil} />
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-movil-current"
                    className="navbar__item-movil"
                    exact
                    to="/premios"
                >
                    <Icon icon={awardIcon} width={sizeIconMovil} height={sizeIconMovil} />
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-movil-current"
                    className="navbar__item-movil"
                    exact
                    to="/publicaciones"
                >
                    <Icon icon={bookIcon} width={sizeIconMovil} height={sizeIconMovil} />
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-movil-current"
                    className="navbar__item-movil"
                    exact
                    to="/conferencias"
                >
                    <Icon icon={lectern} width={sizeIconMovil} height={sizeIconMovil} />
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-movil-current"
                    className="navbar__item-movil"
                    exact
                    to="/medios"
                >
                    <Icon icon={broadcastTower} width={sizeIconMovil} height={sizeIconMovil} />
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-movil-current"
                    className="navbar__item-movil"
                    exact
                    to="/entrevistas"
                >
                    <Icon icon={microphoneAlt} width={sizeIconMovil} height={sizeIconMovil} />
                </NavLink>

                <NavLink
                    activeClassName="navbar__item-movil-current"
                    className="navbar__item-movil"
                    exact
                    to="/proximasact"
                >
                    <Icon icon={calendarAltSolid} width={sizeIconMovil} height={sizeIconMovil} />
                </NavLink>

            </div>
        </nav>
    );
};