import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { validateForm } from '../../validation/registerValidationRules';
import { useForm } from '../../hooks/useForm';
import { empezarRegistro } from '../../actions/auth.actions';

export const RegisterScreen = () => {

    const dispatch = useDispatch();

    const hazRegistro = () => {
        dispatch( empezarRegistro ( email, password, userName ) );
    };

    const [ formValues, handleInputChange, handleSubmit, errors ] = useForm({
            userName: 'Patricia',
            email: 'paty.carrion@gmail.com',
            password: '123456',
            password2: '123456',
        },
        hazRegistro,
        validateForm
    );

    const { userName, email, password, password2 } = formValues;

    return (
        <>
            <h3 className="form__title mb-5">Registro</h3>
            <form onSubmit={ handleSubmit }>
                <div className="form__label-input">
                    <label className="form__label">Nombre</label>
                    <input 
                        className={`form__input ${ errors.userName && 'form__input-error' }` }
                        type="text"
                        name="userName"
                        autoComplete="off"
                        value= { userName }
                        onChange= { handleInputChange }
                    />
                    {errors.userName && (
                        <p className="form__error">{errors.userName}</p>
                    )}
                </div>
                <div className="form__label-input">
                    <label className="form__label">Email</label>
                    <input 
                        className={`form__input ${ errors.email && 'form__input-error' }` }
                        type="text"
                        name="email"
                        autoComplete="off"
                        value= { email }
                        onChange= { handleInputChange }
                    />
                    {errors.email && (
                        <p className="form__error">{errors.email}</p>
                    )}
                </div>
                <div className="form__label-input">
                    <label className="form__label">Password</label>
                    <input
                        className={`form__input ${ errors.password && 'form__input-error' }` }
                        type="password"
                        name="password"
                        value= { password }
                        onChange= { handleInputChange }
                    />
                    {errors.password && (
                        <p className="form__error">{errors.password}</p>
                    )}
                </div>
                <div className="form__label-input mb-5">
                    <label className="form__label">Repite la password</label>                
                    <input
                        className={`form__input ${ errors.password2 && 'form__input-error' }` }
                        type="password"
                        name="password2"
                        value= { password2 }
                        onChange= { handleInputChange }
                    />
                    {errors.password2 && (
                        <p className="form__error">{errors.password2}</p>
                    )}
                </div>
                <button
                    className="boton boton-primary boton-block mb-5"
                    type="submit"
                >
                    Registrarse
                </button>

                <Link
                    to="/auth/login"
                    className='link-underline'
                >
                    ¿Ya te has registrado?
                </Link>
            </form>

        </>

    );
};