import React from 'react';

export const WebAccScreen = () => {
    return (
        <>
            <div className="webAcc__img fade-in">
                <img src="../assets/img/home_bkg.jpg" alt="Ana Carrasco Conde" />
            </div>
            <div className="webAcc__texto">
                <h1 className="webAcc__texto-titulo">Ana Carrasco Conde</h1>
                <h2 className="webAcc__texto-subtitulo">Filósofa</h2>
            </div>
        </>
    );
};
