import { types } from '../types/types';

const initialState = {
    tiposMedioEntr: [],
    tipoMedEntrSeleccionado: null,
    programasEntr: [],
    progEntrSeleccionado: null,
    episodioEntr: [],
    episodioEntrSeleccionado: null,
    videosEntr: [],
    prensaEntr: []
};

export const entrevistasReducer = ( state = initialState, action ) => {

    // console.log('mediosReducer action: ', action);
    // console.log('mediosReducer state: ', state);

    switch ( action.type) {

        case types.tiposMedioEntrListado:
            return{
                ...state,
                tiposMedioEntr: [ ...action.payload ]
            };

        case types.tipoMedioEntrSeleccionado:
            return{
                ...state,
                tipoMedEntrSeleccionado: action.payload
            };

        case types.programasEntrListado:
            return{
                ...state,
                programasEntr: [ ...action.payload ]
            };

        case types.programaEntrSeleccionado:
            return{
                ...state,
                progEntrSeleccionado: action.payload
            };

        case types.episodiosEntrListado:
            return{
                ...state,
                episodiosEntr: [ ...action.payload ]
            };
    
        case types.episodioEntrSeleccionado:
            return{
                ...state,
                episodioEntrSeleccionado: action.payload
            };

        case types.videosEntrListado:
            return{
                ...state,
                videosEntr: [ ...action.payload ]
            };            

        case types.prensaEntrListado:
            return{
                ...state,
                prensaEntr: [ ...action.payload ]
            };            

        default:
            return state;
    }
};