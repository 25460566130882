import React from 'react';

import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import envelope from '@iconify/icons-la/envelope';
import facebookF from '@iconify/icons-la/facebook-f';
import twitterIcon from '@iconify/icons-la/twitter';
import instagramIcon from '@iconify/icons-la/instagram';
import youtubeIcon from '@iconify/icons-la/youtube';

export const SocialBar = () => {
    const sizeIcon = '1.5rem';

    return (
        <div className="socialBar">
            <div className="socialBar__block">
                <Link to={{ pathname: "mailto:anacar03@ucm.es" }} target="_blank" className="socialBar__item">
                    <Icon icon={envelope} color="#363636" width={sizeIcon} height={sizeIcon} />
                </Link>
                <Link to={{ pathname: "https://twitter.com/ACConde_" }} target="_blank" className="socialBar__item">
                    <Icon icon={twitterIcon} color="#363636" width={sizeIcon} height={sizeIcon} />
                </Link>
                <Link to={{ pathname: "https://www.instagram.com/ana.cconde" }} target="_blank" className="socialBar__item">
                    <Icon icon={instagramIcon} color="#363636" width={sizeIcon} height={sizeIcon} />
                </Link>
                <Link to={{ pathname: "https://www.facebook.com/profile.php?id=100007020467640" }} target="_blank" className="socialBar__item">
                    <Icon icon={facebookF} color="#363636" width={sizeIcon} height={sizeIcon} />
                </Link>
                <Link to={{ pathname: "https://www.youtube.com/channel/UCXsNCxIf00o0YXkcK-SBUlg" }} target="_blank" className="socialBar__item">
                    <Icon icon={youtubeIcon} color="#363636" width={sizeIcon} height={sizeIcon} />
                </Link>
            </div>
        </div>
    );
};
