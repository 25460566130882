import { alertaError } from '../helpers/alertas';
import { fetchSinToken } from '../helpers/fetch';
import { types } from '../types/types';
import { uiFinishLoading, uiStartLoading } from './ui.actions';

/**
 * En este ficharo vamos a registrar las acciones que se corresponden a los objetos
 * relacionados con el apartado PUBLICACIONES, es decir:
 *  - Tipos de publicaciones
 *  - Publicaciones
 */

/***
 * LISTADO DE TIPOS DE PUBLICACIÓN 
 * */
export const tiposPublicacionEmpiezaListado = () => {
    return async (dispatch) => {
        try {
            dispatch(uiStartLoading());

            const resp = await fetchSinToken('tiposPublicacion'); // Petición GET por defecto. No se indica
            const body = await resp.json();

            const tiposPublicacion = body.tiposPublicacion;

            dispatch(tiposPublicacionListado(tiposPublicacion));
            dispatch(tipoPublicacionSeleccionado(tiposPublicacion[0]));
            // dispatch(tipoItinerarioEmpiezaListado());

            dispatch(uiFinishLoading());
        } catch (error) {
            alertaError(error);
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const tiposPublicacionListado = (tiposPublicacion) => ({
    type: types.tiposPublicacionListado,
    payload: tiposPublicacion
});

export const tipoPublicacionSeleccionado = (tiposPublicacion) => ({
    type: types.tipoPublicacionSeleccionado,
    payload: tiposPublicacion
});

/***
 * LISTADO DE PUBLICACIONES - LIBROS 
 * */
export const publicacionesEmpiezaListado = (tipoPublicacion) => {
    // console.log( 'publicacionesEmpiezaListado -> tipoPublicacion: ', tipoPublicacion );
    return async (dispatch) => {
        try {
            dispatch(uiStartLoading());

            switch (tipoPublicacion.id) {
                case process.env.REACT_APP_PUBL_LIBROS:
                    const respLibros = await fetchSinToken('libros'); // Petición GET por defecto. No se indica
                    const bodyLibros = await respLibros.json();

                    const libros = bodyLibros.libros;

                    dispatch(librosListado(libros));
                    break;

                case process.env.REACT_APP_PUBL_ARTICULOS:
                    const respArticulos = await fetchSinToken('articulos', { key: 'capitulo', data: false }); // Petición GET por defecto. No se indica
                    const bodyArticulos = await respArticulos.json();

                    const articulos = bodyArticulos.articulos;

                    dispatch(articulosListado(articulos));
                    break;

                case process.env.REACT_APP_PUBL_CAPITULOS: // Es la misma consultas que artículos, con el atributo a true
                    const respCapitulos = await fetchSinToken('articulos', { key: 'capitulo', data: true }); // Petición GET por defecto. No se indica
                    const bodyCapitulos = await respCapitulos.json();

                    const capitulos = bodyCapitulos.articulos;

                    dispatch(articulosListado(capitulos));
                    break;

                case process.env.REACT_APP_PUBL_REVISTAS:
                    const respRevistas = await fetchSinToken('revista'); // Petición GET por defecto. No se indica
                    const bodyRevistas = await respRevistas.json();

                    const revistas = bodyRevistas.revistas;

                    dispatch(revistasListado(revistas));
                    break;
                default:
                    break;
            }
            dispatch(uiFinishLoading());

        } catch (error) {
            alertaError(error);
        };
    }
};

// Esta no se exporta porque se utiliza en este fichero
const librosListado = (libros) => ({
    type: types.librosListado,
    payload: libros
});

// Esta no se exporta porque se utiliza en este fichero
const revistasListado = (revistas) => ({
    type: types.revistasListado,
    payload: revistas
});

// Esta no se exporta porque se utiliza en este fichero
const articulosListado = (articulos) => ({
    type: types.articulosListado,
    payload: articulos
});